<template>
    <div class="card-header">
        <h2>{{ playbookText }} - <span>Planning</span></h2>
    </div>
    <div v-if="hasPlaybooks"
        class="card card-playbook-activity activityBreakdown">
        <div class="graph-block">
            <div class="nested-card-header header-plus-key has-mobile-break">
                <h3>Days Started Before Due Date</h3>
                <div class="graph-key horizontal">
                    <div class="key-item key-seafoam key-solid key-read">
                        <div class="key-color"></div>
                        <div class="key-label">Day Started</div>
                    </div>
                    <div class="key-item key-total">
                        <div class="key-color"></div>
                        <div class="key-label">Total Days</div>
                    </div>
                </div>
            </div>
            <div class="graph-section horizontal">
                <playbook-history-bar-graph
                    v-for="playbook in playbookActivityHistory.user_playbook_list"
                    :key="playbook.exam_name"
                    :playbook="playbook"
                />
            </div>
        </div>
    </div>
    <div v-else class="card card-empty">
        <div class="icon-container">
            <svg class="empty-icon" role="presentation">
                <use href="/static/img/IconSprite.svg#playbook"></use>
            </svg>
        </div>
        <p v-if="coach_name">There are currently no Playbooks available in {{ coach_name }}.</p>
        <p v-else>There are currently no Playbooks available.</p>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useStudentActivityStore } from '../../stores/activity-history.js';

import { loadingMixin } from '../../mixins/loading.js';

import PlaybookHistoryBarGraph from "./PlaybookHistoryBarGraph.vue";

export default {
  name: "PlaybookActivityHistory",
  mixins: [loadingMixin],
  components: {
    PlaybookHistoryBarGraph,
  },
  data() {
    return {
      coach_name: "",
    };
  },
  methods: {
    ...mapActions(useStudentActivityStore, ['getActivityExamPlaybookAnalytics']),
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([this.getActivityExamPlaybookAnalytics()]).then(() => {
        this.stopLoading();
      });
    },
  },
  computed: {
    ...mapState(useStudentActivityStore, ['playbookActivityHistory']),
    playbookText() {
      if(this.playbookActivityHistory.display_exam_playbook) {
        return "Exam Playbook";
      }
      return "Playbook";
    },
    hasPlaybooks() {
      return this.playbookActivityHistory.user_playbook_list?.length;
    },
  },
  created() {
    this.loadInterfaceData();
  },
  mounted() {
    this.coach_name = document.getElementById("PlaybookActivityHistory").getAttribute("coach_name");
  }
};
</script>
