<template>
  <modal
    :modalClasses="'active'"
    v-if="isOpen"
    v-on:modal-cancel="toggleTipOpenState()"
  >
    <carouseloftipscard></carouseloftipscard>
  </modal>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTipsStore } from '../../stores/tips.js';

import Modal from "../generic/Modal.vue";
import CarouselOfTipsCard from "./CarouselOfTipsCard.vue";
import * as loadTips from '../../../js/content-scripts.js';

export default {
  name: "CarouselOfTipsModal",
  data() {
    return {};
  },
  components: {
    modal: Modal,
    carouseloftipscard: CarouselOfTipsCard,
  },
  methods: {
    ...mapActions(useTipsStore, [
      'toggleTipOpenState',
    ]),
  },
  computed: {
    ...mapState(useTipsStore, [
      'isOpen',
    ]),
  },
  mounted: function () {
    loadTips.loadContent();
  }
};
</script>
