<template>
    <div class="console-form">
        <div class="header-section">
            <h1 v-if="existingInstance">Edit Rule</h1>
            <h1 v-else>Add Rule</h1>
            <button v-if="existingInstance" type="button"
                class="btn btn-delete"
                @click="deleteInstance()">
                Delete
            </button>
        </div>
        <FormKit
            v-model="formData"
            :input-errors="formErrors"
            :actions="false"
            type="form"
            >
            <ul class="form-field-errors" v-if="formErrors.non_field_errors">
              <li class="form-field-error" v-for="error in formErrors.non_field_errors">{{ error }}</li>
            </ul>

            <FormKit
              name="characteristic"
              label="MTS Characteristic"
              outer-class="survey_question"
              label-class="survey_prompt"
              placeholder="MTS Characteristic"
              type="select"
              validation="required"
              :options="characteristicOptions"
              @change="resetIncludeValues"
            >
              <template #label="context">
                <div :class="context.classes.label" :for="context.id">
                  MTS Characteristic <span class="required-item">*</span>
                </div> 
              </template>
            </FormKit>

            <FormKit
              name="operation"
              label="Operation"
              outer-class="survey_question"
              label-class="survey_prompt"
              placeholder="Operation"
              type="select"
              validation="required"
              :options="validOperationOptions"
            >
                <template #label="context">
                  <div :class="context.classes.label" :for="context.id">
                    Operation <span class="required-item">*</span>
                  </div> 
                </template>
            </FormKit>

            <FormKit
              v-if="isIncludeOperation"
              name="values_for_include"
              label="Include Value(s)"
              outer-class="survey_question"
              legend-class="survey_prompt"
              fieldset-class="survey_responses"
              options-class="survey_responses"
              placeholder="Value"
              :type="valueFieldType"
              validation="required"
              :options="valueOptions"
              :min="rangeMin"
              :max="rangeMax"
            />

            <FormKit
              v-else-if="valueFieldType === 'select'"
              name="value"
              label="Value"
              outer-class="survey_question"
              label-class="survey_prompt"
              placeholder="Value"
              :type="valueFieldType"
              validation="required"
              :options="valueOptions"
              :min="rangeMin"
              :max="rangeMax"
            />

            <FormKit
              v-else-if="valueFieldType === 'number'"
              name="value"
              label="Value"
              outer-class="survey_question"
              label-class="survey_prompt"
              placeholder="Value"
              :type="valueFieldType"
              validation="required"
              :options="valueOptions"
              :min="rangeMin"
              :max="rangeMax"
            />

            <FormKit
              v-else-if="valueFieldType === 'text'"
              name="value"
              label="Value"
              outer-class="survey_question"
              label-class="survey_prompt"
              placeholder="Value"
              :type="valueFieldType"
              validation="required"
              :options="valueOptions"
              :min="rangeMin"
              :max="rangeMax"
            />

            <div class="error-container" v-if="formErrors.value && isIncludeOperation">
              <p class="form-field-errors" v-for="error in formErrors.value">{{ error }}</p>
            </div>

            <div class="button-block">
              <button type="button" class="btn btn-cancel"
                @click="cancelForm">
                Cancel
              </button>
              <button type="button" class="btn"
                @click="saveInstance">
                Save
              </button>
            </div>
        </FormKit>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useTailoringStore } from './stores/tailoring.js'

import { formDataMixin } from '../../mixins/formData.js';

const EQUAL_OPERATION = 'equal';
const GREATER_OPERATION = 'greater';
const GREATER_OR_EQUAL_OPERATION = 'greater_or_equal';
const LESS_OPERATION = 'less';
const LESS_OR_EQUAL_OPERATION = 'less_or_equal';
const INCLUDES_OPERATION = 'includes';

export default {
  name: 'LogicRuleForm',
  props: {
    cohort: {
      type: Object,
    },
  },
  data() {
    return {
      operationOptions: [
        { label: '----', value: ""},
        { label: 'equal to', value: EQUAL_OPERATION },
        { label: 'greater than', value: GREATER_OPERATION },
        { label: 'greater than or equal to', value: GREATER_OR_EQUAL_OPERATION },
        { label: 'less than', value: LESS_OPERATION },
        { label: 'less than or equal to', value: LESS_OR_EQUAL_OPERATION },
        { label: 'includes', value: INCLUDES_OPERATION },
      ],
      valueCharacteristicOperations: [
        EQUAL_OPERATION,
        INCLUDES_OPERATION,
      ],
      multiValueCharacteristicOperations: [
        "",
        INCLUDES_OPERATION,
      ],
      rangeCharacteristicOperations: [
        EQUAL_OPERATION,
        GREATER_OPERATION,
        GREATER_OR_EQUAL_OPERATION,
        LESS_OPERATION,
        LESS_OR_EQUAL_OPERATION,
      ],
    }
  },
  mixins: [
    formDataMixin
  ],
  computed: {
    ...mapState(useTailoringStore, [
      'cohortGroups',
      'mtsdictionaryData',
      'sources',
      'characteristics',
      'sourcedCharacteristics'
    ]),
    existingInstance() {
      return !!this.formData.id;
    },
    characteristicOptions () {
      let characteristics = this.characteristics;
      return [this.emptyOption].concat(characteristics.map(characteristic => {
        return {
          label: characteristic.name,
          value: characteristic.name,
        }
      }));
    },
    selectedCharacteristic () {
      let characteristicName = this.formData.characteristic;
      return this.characteristics.find(characteristic => characteristic.name === characteristicName);
    },
    selectedRestrictionset () {
      let characteristic = this.selectedCharacteristic;
      return characteristic ? characteristic.restrictionset : null;
    },
    isValueCharacteristic () {
      let restrictionset = this.selectedRestrictionset;
      return restrictionset ? !!restrictionset.values.length : false;
    },
    isRangeCharacteristic () {
      let restrictionset = this.selectedRestrictionset;
      return restrictionset ? !!restrictionset.ranges.length : false;
    },
    rangeOperations () {
      let rangeOptions = this.rangeCharacteristicOperations;
      return this.operationOptions.filter(option => rangeOptions.includes(option.value));
    },
    valueOperations () {
      let valueOptions = (
        this.selectedCharacteristic && this.selectedCharacteristic.is_multivalued
        ? this.multiValueCharacteristicOperations
        : this.valueCharacteristicOperations
      );
      return this.operationOptions.filter(option => valueOptions.includes(option.value));
    },
    isIncludeOperation () {
      let operation = this.formData.operation;
      return operation ? operation === INCLUDES_OPERATION : false;
    },
    isValueOperation () {
      let operation = this.formData.operation;
      return operation ? operation !== INCLUDES_OPERATION : false;
    },
    validOperationOptions () {
      if (this.isRangeCharacteristic) {
        return this.rangeOperations;
      } else if (this.isValueCharacteristic) {
        return this.valueOperations;
      }
      return this.rangeOperations;
    },
    valueOptions () {
      let restrictionset = this.selectedRestrictionset;
      if (restrictionset) {
        let selectedCharacteristic = this.selectedCharacteristic;
        let defaultValue = selectedCharacteristic ? selectedCharacteristic.default : null;
        return this.isRangeCharacteristic ? this.rangeValueOptions : restrictionset.values.map(valueOption => {
          return (String(valueOption.value) === String(defaultValue)
            ? {label: "No Answer", value: valueOption.value}
            : {label: valueOption.label || valueOption.value, value: valueOption.value})
        });
      }
      return [];
    },
    valueFieldType () {
      if (this.isIncludeOperation) {
        return "checkbox";
      } else if (this.isRangeCharacteristic) {
        return "number";
      } else if (this.isValueCharacteristic) {
        return "select";
      } else {
        return "text";
      }
    },
    rangeRestriction () {
      if (this.isRangeCharacteristic) {
        let restrictionset = this.selectedRestrictionset;
        let rangeRestriction = restrictionset.ranges[0];
        return rangeRestriction;
      }
      return null;
    },
    rangeValueOptions () {
      if (this.isRangeCharacteristic) {
        let rangeRestriction = this.rangeRestriction;
        let rangeLength = rangeRestriction.max - rangeRestriction.min;
        return Array.from({ length: rangeLength + 1 }, (_, i) => i + rangeRestriction.min);
      }
      return [];
    },
    rangeMin () {
      let rangeRestriction = this.rangeRestriction;
      return rangeRestriction ? rangeRestriction.min : null;
    },
    rangeMax () {
      let rangeRestriction = this.rangeRestriction;
      return rangeRestriction ? rangeRestriction.max : null;
    }
  },
  methods: {
    saveInstance() {
      let instance = this.formData;
      instance.cohortId = this.cohort.id;
      this.$emit('save-instance', instance);
    },
    cancelForm() {
      this.$emit('cancel-form');
    },
    deleteInstance() {
      let instance = this.formData;
      instance.cohortId = this.cohort.id;
      this.$emit('delete-instance', instance);
    },
    resetIncludeValues() {
      this.formData.values_for_include = [];
    }
  },
}
</script>
