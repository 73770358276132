<template>
        <div class="card-header">
            <h2>Tips of the Week</h2>
        </div>
        <div v-if="currentCard" class="card card-tips card-has-overflow">
            <div class="loading-container" v-if="loading">
                <div class="loading-indicator"></div>
                <span class="loading-text">Loading...</span>
            </div>
            <template v-else>
                <div class="nested-card-header header-plus-btn">
                    <div class="icon-header">
                        <div class="tips-icon"></div>
                        <div class="tag">Advice</div>
                    </div>
                    <div class="tips-nav card-nav">
                        <button v-if="currentIndex != 0"
                            class="link nav-item nav-prev"
                            type="button"
                            aria-label="Previous Tip"
                            @click="prevCard()">
                            <div class="icon-container container-navy">
                                <svg class="icon-arrow icon-navy" role="presentation">
                                    <use :xlink:href="leftArrow"></use>
                                </svg>
                            </div>
                            Previous
                        </button>
                        <div class="divider"
                            v-if="currentIndex != 0 && currentIndex != numberOfCards - 1">
                        </div>
                        <!-- <span
                            v-for="i in numberOfCards"
                            :key="i"
                            :class="['dot', {'active':i === currentIndex + 1}]"
                            @click="getCard(i)">
                        </span> -->
                        <button v-if="currentIndex != numberOfCards - 1"
                            class="link nav-item nav-next"
                            type="button"
                            aria-label="Next Tip"
                            @click="nextCard()">
                            Next
                            <div class="icon-container container-navy">
                                <svg class="icon-arrow icon-navy" role="presentation">
                                    <use :xlink:href="rightArrow"></use>
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="content-container" v-for="i in [currentIndex]" :key="i">
                    <transition name="fade">
                        <div class="card-content">
                            <div
                                v-html="currentCard.html"
                                class="tip">
                            </div>
                            <!-- <button
                                class="btn btn-seafoam"
                                @click="viewMoreDetails(currentCard)"
                                aria-label="Expand Tips">
                                Expand
                            </button> -->
                            <Teleport to="#slotWrapper">
                                <carousel-of-tips-modal />
                            </Teleport>
                        </div>
                    </transition>
                </div>
            </template>
        </div>
        <div v-else class="card card-empty">
            <div class="icon-container">
                <svg class="empty-icon icon-navy" role="presentation">
                    <use xlink:href="/static/img/IconSprite.svg#tips"></use>
                </svg>
            </div>
            <p>There are currently no tips available.</p>
        </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTipsStore } from '../../stores/tips.js';

import CarouselOfTipsModal from './CarouselOfTipsModal.vue';
import { leftArrow, rightArrow } from "../../images.js";

export default {
  name: "CarouselOfTips",
  data() {
    return {
      currentIndex: 0,
      loading: true,
      leftArrow: leftArrow,
      rightArrow: rightArrow,
    };
  },
  components: {
    'carousel-of-tips-modal': CarouselOfTipsModal
  },
  methods: {
    ...mapActions(useTipsStore, [
      'loadTipsData',
      'displaySelectedTip',
      'toggleTipOpenState',
    ]),
    getCard: function(index) {
      this.currentIndex = index - 1;
    },
    viewMoreDetails: function(currentCard) {
      this.displaySelectedTip(currentCard);
      this.toggleTipOpenState();
    },
    nextCard: function() {
      this.currentIndex === this.numberOfCards - 1 ? this.currentIndex = 0 : this.currentIndex++;
    },
    prevCard: function() {
      this.currentIndex === 0 ? this.currentIndex = this.numberOfCards - 1 : this.currentIndex--;
    }
  },
  computed: {
    ...mapState(useTipsStore, [
      'tipsData',
      'currentTip',
      'isOpen',
    ]),
    currentCard () {
      return this.numberOfCards ? this.tipsData[this.currentCardIndex] : null;
    },
    currentCardIndex () {
      return Math.abs(this.currentIndex) % this.numberOfCards
    },
    numberOfCards () {
      return this.tipsData.length;
    },
    slicedCardDetails () {
      let cardHtml = this.currentCard.html;
      let cardText = $(cardHtml)[0];
      let shortenedText = cardText.textContent.slice(0, 200);
      return cardText.textContent.length > 200 ? `${shortenedText}...` : shortenedText;
    },
  },
  async created() {
    await this.loadTipsData();
    this.loading = false;
  },
};
</script>
