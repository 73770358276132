<template>
<div class="distribution-data">
    <div class="selection-details">
        <p class="selected-char"><b>Selected Characteristic:</b> <span>{{ characteristicDefinition.name }}</span></p>
        <p class="question-text" v-if="characteristicDefinition.question"><b>Question Prompt:</b> <span>“{{ characteristicDefinition.question }}”</span></p>
    </div>
    <div class="table-view">
        <div class="table-view-header">
            <div class="source">Source</div>
            <div class="value">Value</div>
            <div class="has-subheaders">
                <div class="header">Students</div>
                <div class="subheaders">
                    <div>Current</div>
                    <div>Group Total</div>
                </div>
            </div>
        </div>
        <div class="char-values-container">
            <div class="char-value-details table-row" v-for="[key, users] in distributionKeysAndValues">
                <template v-if="sourceExists(key)">
                    <div class="source">{{ key.split('__')[0] }}</div>
                    <div class="value">{{ key.split('__')[1] }}</div>
                </template>
                <template v-else>
                    <div class="source empty"><span>No source</span></div>
                    <div class="value" >{{ key }}</div>
                </template>
                <div class="membership">
                    <div class="current">{{ users.length }}</div>
                    <div class="total">{{ cohort.membership_count }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from "pinia";
import { useTailoringStore } from './stores/tailoring.js'

export default {
  name: 'InsightCharacteristic',
  components: {},
  props: {
    characteristic: {
      type: String,
      required: true,
    },
    distribution: {
      type: Object,
    },
    cohort: {
      type: Object,
    }
  },
  data () {
    return {};
  },
  computed: {
    ...mapState(useTailoringStore, [
      'activeCohort',
      'enrollmentCount',
      'tailoringDistribution',
      'successUpdateTaskDate',
      'startedUpdateTaskDate',
      'characteristics',
    ]),
    distributionKeysAndValues () {
      return Object.entries(this.distribution);
    },
    characteristicDefinition () {
      return this.characteristics.find(characteristicDefinition => characteristicDefinition.name === this.characteristic);
    },
  },
  methods: {
    sourceExists( key ) {
      return key.split("__").length > 1;
    }
  }

}

</script>
