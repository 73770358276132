<template>
    <div class="groups-section">
        <div class="section-header has-border">
            <h3>Insight Groups</h3>
            <div class="actions">
                <a class="btn btn-add" :href="addCohortUrl">Add Group</a>
                <label class="view-toggle" v-bind:class = "{ checked : !cohortViewMode }">
                    <input type="checkbox" v-model="cohortViewMode" />
                    <span class="icon-container">
                        <span class="view-icon card-view">
                            <svg class="card-view-icon" role="presentation">
                                <use href="/static/img/IconSprite.svg#cardView"></use>
                            </svg>
                        </span>
                        <span class="view-icon table-view">
                            <svg class="table-view-icon" role="presentation">
                                <use href="/static/img/IconSprite.svg#tableView"></use>
                            </svg>
                        </span>
                    </span>
                </label>
            </div>
        </div>
        <div class="empty" v-if="!cohorts.length">
            No groups have been created
        </div>
        <template v-else>
            <div v-if="cohortViewMode" class="groups card-view">
                <cohort-card-details-card-view
                    v-for="cohort in ungroupedCohorts"
                    :key="cohort.id"
                    :cohort="cohort"
                />
            </div>
            <div v-else class="groups table-view">
                <div class="table-view-header">
                    <div class="primary-header">Name</div>
                    <div class="has-subheaders">
                        <div class="header">Students</div>
                        <div class="subheaders">
                            <div>Current</div>
                            <div>Total</div>
                            <div>Percent</div>
                        </div>
                    </div>
                    <div class="action-column">Action</div>
                </div>
                <cohort-card-details-table-view
                    v-for="cohort in ungroupedCohorts"
                    :key="cohort.id"
                    :cohort="cohort"
                />
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTailoringStore } from './stores/tailoring.js'

import LogicRuleForm from "./LogicRuleForm.vue";
import LogicRuleRow from "./LogicRuleRow.vue";
import CohortCardDetailsCardView from "./CohortCardDetailsCardView.vue";
import CohortCardDetailsTableView from "./CohortCardDetailsTableView.vue";
import { cohortDetailUrl, cohortAddPageUrl } from "./urls.js";

export default {
  name: 'CohortList',
  components: {
    LogicRuleForm,
    LogicRuleRow,
    CohortCardDetailsCardView,
    CohortCardDetailsTableView,
  },
  props: {
    cohorts: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
      activeCohortId: null,
      showRuleForm: false,
      ruleInstance: {},
      cohortViewMode: true,
    };
  },
  computed: {
    ...mapState(useTailoringStore, [
      'coachId',
      'enrollmentCount',
      'ungroupedCohorts',
    ]),
    addCohortUrl () {
      return cohortAddPageUrl.stringify({coachId: this.coachId});
    },
  },
  methods: {
    ...mapActions(useTailoringStore, [
      'saveCohortRule',
      'deleteCohortRule',
      'getCohortList',
    ]),
    saveRule(instance) {
      this.saveCohortRule(instance).then(() => {
        this.resetForm();
        this.getCohortList();
      });
    },
    deleteRule(instance) {
      this.deleteCohortRule(instance).then(() => {
        this.resetForm();
        this.getCohortList();
      });
    },
    createRule(cohort) {
      this.activeCohortId = cohort.id;
      this.showRuleForm = true;
      this.ruleInstance = {};
    },
    editRule(rule) {
      this.activeCohortId = rule.cohort_id;
      this.showRuleForm = true;
      this.ruleInstance = rule;
    },
    resetForm() {
      this.showRuleForm = false;
      this.activeCohortId = null;
      this.ruleInstance = {};
    },
    getCohortDetailUrl(cohort) {
      return cohortDetailUrl.stringify({
        coachId: this.coachId,
        cohortId: cohort.id
      });
    },
  },
}

</script>
