<template>
    <div>
        <div class="loading-container" v-if="loading">
            <div class="loading-indicator"></div>
            <span class="loading-text">Loading...</span>
        </div>
        <template v-else>
            <cohort-form
                :instanceData="instance"
                :formErrors="formErrors"
                :editing="editing"
                @save-instance="saveCohortInstance"
                @cancel-form="resetInterface"
                @delete-instance="deleteCohortInstance"
            />
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTailoringStore } from './stores/tailoring.js';

import { loadingMixin } from "../../mixins/loading.js";
import { cohortManagerUrl } from "./urls.js";

import CohortForm from './CohortForm.vue';
import LogicRuleForm from './LogicRuleForm.vue';

export default {
  name: 'AddCohortPage',
  mixins: [
    loadingMixin,
  ],
  components: {
    CohortForm,
    LogicRuleForm,
  },
  props: {
    instanceObject: {
      type: Object,
      default: () => {
        return {}
      },
    }
  },
  data () {
    return {
      showCohortForm: false,
      showGroupForm: false,
      instance: this.instanceObject,
      formErrors: {},
    };
  },
  computed: {
    editing () {
      return !!this.instance.id;
    },
    ...mapState(useTailoringStore, [
      'cohorts',
      'coachId',
    ]),
    redirectUrl() {
      return cohortManagerUrl.stringify({coachId: this.coachId});
    },
  },
  methods: {
    ...mapActions(useTailoringStore, [
      'getCohortGroupList',
      'getCohortList',
      'getCohortData',
      'getMTSDictionaryData',
      'saveCohortGroup',
      'deleteCohortGroup',
      'saveCohort',
      'deleteCohort',
      'getEnrollmentCount',
    ]),
    saveCohortInstance(instanceData) {
      this.saveCohort(instanceData).then((response) => {
        if (response.errors) {
          this.formErrors = response.errors;
          this.loading = false;
        } else {
          this.resetInterface();
        }
      });
      this.closeForm();
    },
    deleteCohortInstance(instanceData) {
      this.deleteCohort(instanceData).then(() => {
        this.resetInterface();
      });
      this.closeForm();
    },
    resetInterface() {
      window.location.href = this.redirectUrl;
    },
    closeForm() {
      this.loading = true;
    },
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([
        this.getEnrollmentCount(),
        this.getCohortGroupList(),
        this.getCohortList(),
        this.getMTSDictionaryData(),
      ]).then(() => {
        this.stopLoading();
      });
    },
  },
  created () {
    this.loadInterfaceData();
  },
}

</script>
