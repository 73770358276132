import { defineStore } from 'pinia';

import {
    activityCoachUserAnalyticsUrl,
    activityListUrl,
    activityListNoCreditUrl,
    activityMessageAnalyticsUrl,
    activitySurveyAnalyticsUrl,
    activityToDoAnalyticsUrl,
    activityExamPlaybookAnalyticsUrl,
    coachHistoryUrl,
    coachListURL,
    institutionHistoryUrl,
} from '../urls.js';

import { genericMixin } from '../stores/generic.js';

const coachHistoryInfo = coachHistoryUrl.match(location.pathname);
const coachId = coachHistoryInfo ? Number(coachHistoryInfo.coachId) : null;

const institutionInfo = institutionHistoryUrl.match(location.pathname);
const institutionId = institutionInfo ? Number(institutionInfo.institutionId) : null;

const initialState = {
  coachId,
  institutionId,
  coachList: [],
  coachUserActivityHistory: [],
  bulletinActivityHistory: [],
  playbookActivityHistory: [],
  surveyActivityHistory: [],
  toDoActivityHistory: [],
  userActivity: [],
  userCredits: [],
}

export const useStudentActivityStore = defineStore('activity-history', {
  state: () => initialState,
  getters: {
    ...genericMixin.getters,
  },
  actions: {
    ...genericMixin.actions,
    setUserCreditList ({ data }) {
      this.userCredits = data;
    },
    setUserActivityList ({ data }) {
      this.userActivity = data;
    },
    setActivityMessageAnalytics ({ data }) {
      this.bulletinActivityHistory = data;
    },
    setActivitySurveyAnalytics ({ data }) {
      this.surveyActivityHistory = data;
    },
    setActivityToDoAnalytics ({ data }) {
      this.toDoActivityHistory = data;
    },
    setActivityExamPlaybookAnalytics ({ data }) {
      this.playbookActivityHistory = data;
    },
    setActivityCoachUserAnalytics ({ data }) {
      this.coachUserActivityHistory = data;
    },
    setCoachList ({ data }) {
      this.coachList = data;
    },
    getCoachList () {
      return this.loadObjectList({
        mutation: this.setCoachList,
        urls: { list: coachListURL },
        urlParams: { institutionId: this.institutionId }
      });
    },
    getUserCreditList () {
      return this.loadObjectList({
        mutation: this.setUserCreditList,
        urls: { list: activityListUrl },
        urlParams: { coachId: this.coachId }
      });
    },
    getUserActivityList () {
      return this.loadObjectList({
        mutation: this.setUserActivityList,
        urls: { list: activityListNoCreditUrl },
        urlParams: { coachId: this.coachId }
      });
    },
    getActivityCoachUserAnalytics () {
      return this.loadObjectList({
        mutation: this.setActivityCoachUserAnalytics,
        urls: { list: activityCoachUserAnalyticsUrl },
        urlParams: {
          coachId: this.coachId
        }
      });
    },
    getActivityMessageAnalytics () {
      return this.loadObjectList({
        mutation: this.setActivityMessageAnalytics,
        urls: { list: activityMessageAnalyticsUrl },
        urlParams: {
          coachId: this.coachId
        }
      });
    },
    getActivitySurveyAnalytics () {
      return this.loadObjectList({
        mutation: this.setActivitySurveyAnalytics,
        urls: { list: activitySurveyAnalyticsUrl },
        urlParams: { coachId: this.coachId }
      });
    },
    getActivityToDoAnalytics () {
      return this.loadObjectList({
        mutation: this.setActivityToDoAnalytics,
        urls: { list: activityToDoAnalyticsUrl },
        urlParams: { coachId: this.coachId }
      });
    },
    getActivityExamPlaybookAnalytics () {
      return this.loadObjectList({
        mutation: this.setActivityExamPlaybookAnalytics,
        urls: { list: activityExamPlaybookAnalyticsUrl },
        urlParams: { coachId: this.coachId }
      });
    },
    setCoachId(payload) {
      this.coachId = payload;
    },
  },
});
