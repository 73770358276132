<template>
  <div class="logic-rule-row">

  </div>

</template>

<script>

export default {
  name: 'LogicRuleRow',
  data () {
    return {};
  },
  computed: {

  },
  methods: {

  },
  created () {

  },
}

</script>
