<template>
    <div class="card-header">
        <h2>Coach Activity Breakdown</h2>
        <div class="card-nav">
            <template v-if="coachList.length >= 1">
                <label class="sr-only" for="coachFilter">Coach:</label>
                <select v-model="selectedCoach" class="btn btn-white btn-select filter-item" id="coachFilter">
                    <option v-for="coach in coachList" :value="coach.id">{{coach.label}}</option>
                </select>
            </template>
            <a
                class="link"
                id="coachAccessLink"
                :href="coachUrl">
                Enter Coach
                <div class="icon-container">
                    <svg class="icon-arrow icon-navy" role="presentation">
                        <use href="/static/img/arrows.svg#rightArrow"></use>
                    </svg>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { coachHomeUrl } from '../../urls.js';
import { useStudentActivityStore } from '../../stores/activity-history.js';

export default {
    name: "CoachActivityHeader",
    emits: ['select-coach'],
    data() {
        return {
            selectedCoach: null,
        }
    },
    computed: {
        ...mapState(useStudentActivityStore, [
            'coachId',
            'coachList',
        ]),
        coachUrl() {
            return coachHomeUrl.stringify({coachId: this.coachId});
        },
    },
    watch: {
        selectedCoach(newValue, oldValue) {
            if(oldValue && oldValue !== newValue) {
                this.$emit('select-coach', newValue);
            }
        }
    },
    created() {
        this.selectedCoach = this.coachId;
    }
}
</script>