<template>
    <div class="task-item table-row" role="row">
        <div class="mobile-portrait-task-item mobile-portrait" role="row">
            <div class="title-status">
                <div class="task-detail column-item column-title truncate" role="cell">
                    <div :class="`category-tag tag-${credit.content_tag}`"
                        :aria-label="`Content Type: ${contentType}`">
                        <span class="color-indicator"></span>
                    </div>
                    <div class="title truncate" v-html="contentTitle"></div>
                </div>
                <div class="task-detail column-item column-status truncate" role="cell">
                    <i>Status: </i>
                    <div class="status-icon large-status-icon" :class="credit.status" :aria-label="`Credit ${accessibleCreditStatus}`"></div>
                </div>
            </div>
            <div class="date-details">
                <div class="task-detail column-item column-deadline truncate" role="cell">
                    <i>Deadline:</i> {{ creditDue }}
                </div>
                <div class="task-detail column-item column-completed truncate" role="cell">
                    <i>Completed:</i> {{ creditCompleted }}
                </div>
            </div>
        </div>
        <div class="task-detail column-item column-title portrait-hidden mobile-hidden truncate" role="cell">
            <div class="title truncate" v-html="contentTitle"></div>
            <div :class="`category-tag tag-${credit.content_tag}`"
                :aria-label="`Content Type: ${contentType}`">
                <span class="color-indicator"></span>
                <span class="tag-label" aria-hidden="true">{{ contentType }}</span>
            </div>
        </div>
        <div class="task-detail column-item column-deadline portrait-hidden mobile-hidden truncate"
            role="cell">
            {{ creditDue }}
        </div>
        <div class="task-detail column-item column-completed portrait-hidden mobile-hidden truncate"
            role="cell">
            {{ creditCompleted }}
        </div>
        <div class="task-detail column-item column-status portrait-hidden mobile-hidden truncate"
            role="cell">
            <div class="status-icon large-status-icon" :class="credit.status" :aria-label="`Credit ${accessibleCreditStatus}`"></div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "ActivityHistoryItem",
  props: {
    credit: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    creditDue () {
        return this.prettyDate(this.credit.due);
    },
    creditCompleted () {
        return this.prettyDate(this.credit.completed);
    },
    accessibleCreditStatus () {
        let status = this.credit.status;

        if(status == 'fail'){
            return `missed`;
        } else if(status == 'success'){
            return `received`;
        } else {
            return `pending`;
        }
    },
    playbookContentType () {
      return this.credit.content_tag == 'playbook';
    },
    contentType () {
      return this.playbookContentType ? `Playbook` : this.credit.content_label;
    },
    contentTitle () {
      return this.playbookContentType
        ? `${this.credit.title} - <i>${this.credit.content_label}</i>`
        : this.credit.title;
    },
  },
  methods: {
    prettyDate(timestamp) {
        if(timestamp == 'N/A' || timestamp == 'No Due Date') {
            return timestamp;
        }
        return moment(timestamp).format('M.D.YY - h:mmA');
    }
  },
  data() {
    return {};
  },
};
</script>
