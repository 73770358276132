<template>
    <a class="card card-bulletin"
        :class="bulletinClass"
        :href="bulletinUrl"
        >
        <div class="status-label scheduled" v-if="bulletin.is_queued">Scheduled</div>
        <div class="status-label draft" v-else-if="bulletin.under_review">Drafted</div>
        <div class="badge" v-if="isUnread"></div>
        <div class="msg-img">
            <img :src="bulletin.icon_url" alt="">
        </div>
        <div class="details-container">
            <div class="msg-info">
                <h3>{{ bulletin.title }}</h3>
                <template v-if="bulletin.related_credit">
                    <div class="status-label credit">For Credit</div>
                    <div v-if="creditPending" class="status-label credit" >For Credit</div>
                    <div v-else class="status-label credit has-status">Credit Status:
                        <div class="status-icon" :class="bulletin.user_progress_status"></div>
                        <div class="sr-item">
                            <template v-if="creditFail">
                                You did not receive credit for this bulletin.
                            </template>
                            <template v-if="creditSuccess">
                                You received credit for this bulletin.
                            </template>
                        </div>
                    </div>
                </template>
                <h4 v-if="bulletin.subtitle">{{ bulletin.subtitle }}</h4>
                <p class="description" v-if="bulletin.description" v-html="bulletin.description"></p>
            </div>
            <div class="date-star">
                <div class="date" v-if="bulletin.is_published || bulletin.is_queued">
                    {{ bulletin.publish_timestamp }}
                </div>
                <button class="btn btn-star toggleStarring"
                    type="button" @click.stop.prevent="toggleBulletinStarStatus()">
                    <svg class="star-icon">
                        <use xlink:href="/static/img/star.svg#starFilled" v-if="starred"></use>
                        <use xlink:href="/static/img/star.svg#star" v-else></use>
                    </svg>
                </button>
            </div>
        </div>
    </a>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useBulletinStore } from './bulletin.js';

import {
  coachBulletinPageUrl,
  coachInteractiveBulletinPageUrl,
  institutionBulletinPageUrl,
  institutionInteractiveBulletinPageUrl,
} from "../../urls.js";

export default {
  name: 'BulletinListCard',
  props: {
    bulletin: {
      type: Object,
      required: true
    },
    starredState: {
      type: Boolean
    }
  },
  data () {
    return {
      pendingStatus: "pending",
      successStatus: "success",
      failStatus: "fail",
      starred: this.starredState,
    };
  },
  computed: {
    ...mapState(useBulletinStore, [
      'activeBulletinId',
      'coachId',
      'institutionId',
    ]),
    creditSuccess () {
      return this.bulletin.user_progress_status === this.successStatus;
    },
    creditFail () {
      return this.bulletin.user_progress_status === this.failStatus;
    },
    creditPending () {
      return this.bulletin.user_progress_status === this.pendingStatus;
    },
    isUnread () {
      return !this.bulletin.viewed;
    },
    isActive () {
      return Number(this.activeBulletinId) === this.bulletin.id;
    },
    bulletinClass () {
      return {
        unread: this.isUnread,
        active: this.isActive,
      }
    },
    isCoachBulletin () {
      return this.coachId;
    },
    baseUrlParams () {
      return {
        coachId: this.coachId,
        bulletinId: this.bulletin.id,
        institutionId: this.institutionId,
        pageId: 0,
      };
    },
    bulletinUrl () {
      if (this.isCoachBulletin && !this.bulletin.is_interactive) {
        return coachBulletinPageUrl.stringify(this.baseUrlParams);
      } else if (this.isCoachBulletin && this.bulletin.is_interactive) {
        return coachInteractiveBulletinPageUrl.stringify(this.baseUrlParams);
      } else if (this.bulletin.is_interactive) {
        return institutionInteractiveBulletinPageUrl.stringify(this.baseUrlParams);
      } else {
        return institutionBulletinPageUrl.stringify(this.baseUrlParams);
      }
    },
  },
  methods: {
    ...mapActions(useBulletinStore, [
      'loadBulletinList',
      'starBulletinToggle',
    ]),
    toggleBulletinStarStatus(instanceData) {
      this.starred = !this.starred;
      this.starBulletinToggle(this.bulletin).then((response) => {
        this.loadBulletinList();
      });
    },
  },
  watch: {
    starredState () {
      this.starred = this.starredState;
    }
  }
}
</script>
