<template>
    <div class="card-header">
        <h2>Completion History Overview</h2>
    </div>
    <div class="loading-container" v-if="loading">
        <div class="loading-indicator"></div>
        <span class="loading-text">Loading...</span>
    </div>
    <template v-else>
        <div v-if="!userCredits.length && !userActivity.length" class="card card-empty">
            <div class="icon-container">
                <svg class="empty-icon" role="presentation">
                    <use href="/static/img/IconSprite.svg#percent"></use>
                </svg>
            </div>
            <p v-if="coach_name">There are currently no assignments available in {{ coach_name }}.</p>
            <p v-else>There are currently no assignments available.</p>
        </div>
        <template v-else>
            <div v-if="userCredits.length" class="card card-credit-items">
                <div class="nested-card-header">
                    <h3>For Credit Items</h3>
                    <p>
                        <i>
                            This list shows what you’ve completed in ECoach. It may take a few
                            days for your instructor to update your grade.
                        </i>
                    </p>
                </div>
                <div class="task-table"
                    role="table"
                    :aria-label="`${coach_name} Completion History Overview | Credit Items`">
                    <div class="table-header task-header table-row portrait-hidden mobile-hidden"
                        role="row">
                        <span class="header-item column-item column-title"
                            role="columnheader">
                            Credit Item Title
                        </span>
                        <span class="header-item column-item column-deadline"
                            role="columnheader">
                            Credit Deadline
                        </span>
                        <span class="header-item column-item column-completed"
                            role="columnheader">
                            Completed
                        </span>
                        <span class="header-item column-item column-status"
                            role="columnheader">
                            Credit Status
                        </span>
                    </div>
                    <div class="task-list">
                        <activity-history-item
                            v-for="credit in userCredits"
                            :key="activityItemKey(credit)"
                            :credit="credit"
                        />
                    </div>
                </div>
            </div>
            <div v-else class="card card-empty">
                <div class="icon-container">
                    <svg class="empty-icon" role="presentation">
                        <use href="/static/img/IconSprite.svg#percent"></use>
                    </svg>
                </div>
                <p v-if="coach_name">There are currently no assignments available for credit in {{ coach_name }}.</p>
                <p v-else>There are currently no assignments available for credit.</p>
            </div>
            <div v-if="userActivity.length" class="card card-non-credit-items">
                <div class="nested-card-header">
                    <h3>Other Items</h3>
                </div>
                <div class="task-table"
                    role="table"
                    :aria-label="`${coach_name} Completion History Overview | Other Items`">
                    <div class="table-header task-header table-row portrait-hidden mobile-hidden"
                        role="row">
                        <span class="header-item column-item column-title"
                            role="columnheader">
                            Title
                        </span>
                        <span class="header-item column-item column-deadline"
                            role="columnheader">
                            Due
                        </span>
                        <span class="header-item column-item column-completed"
                            role="columnheader">
                            Completed
                        </span>
                        <span class="header-item column-item column-status"
                            role="columnheader">
                            Status
                        </span>
                    </div>
                    <div class="task-list">
                        <activity-history-item
                            v-for="credit in userActivity"
                            :key="activityItemKey(credit)"
                            :credit="credit"
                        />
                    </div>
                </div>
            </div>
            <div v-else class="card card-empty">
                <div class="icon-container">
                    <svg class="empty-icon" role="presentation">
                        <use href="/static/img/IconSprite.svg#percent"></use>
                    </svg>
                </div>
                <p v-if="coach_name">There are currently no non-credit assignments available in {{ coach_name }}.</p>
                <p v-else>There are currently no non-credit assignments available.</p>
            </div>
        </template>
    </template>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useStudentActivityStore } from '../../stores/activity-history.js';

import { loadingMixin } from "../../mixins/loading.js";
import ActivityHistoryItem from "./ActivityHistoryItem.vue";
export default {
  name: "ActivityHistoryCreditOverview",
  mixins: [loadingMixin],
  components: {
    ActivityHistoryItem,
  },
  data() {
    return {
        coach_name: ""
    };
  },
  methods: {
    ...mapActions(useStudentActivityStore, [
      'getUserCreditList',
      'getUserActivityList',
    ]),
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([
        this.getUserCreditList(),
        this.getUserActivityList(),
      ]).then(() => {
        this.stopLoading();
      });
    },
    activityItemKey(credit) {
      return credit.type + credit.id;
    },
  },
  computed: {
    ...mapState(useStudentActivityStore, [
        'userCredits',
        'userActivity'
    ]),
  },
  created() {
    this.loadInterfaceData();
  },
  mounted() {
    this.coach_name = document.getElementById("ActivityHistoryCreditOverview").getAttribute("coach_name");
  }
};
</script>
