function exists(value){
    return value != undefined && typeof(value) !== "undefined";
}

var loading_content = false;
function loadContent(){
    if(loading_content || $('[data-load]').length < 1){
        return;
    }

    loading_content = true;
    Promise.all($('[data-load]').get().map(elemContentLoader)).then(function(){
        loading_content = false;
        loadContent();
        drawWidgets();
    });
}

function elemContentLoader(elem){
    return new Promise(function(resolve, reject) {
        console.log('Loading Content', elem, Date());
        var me = $(elem);
        me.html(get_loading_indicator());
        resolve(me);
    }).then(function(elem){
        console.log("Replacing Content", elem);
        return ajaxReplace(elem, elem.data('load'));
    });
}

function drawWidgets(){
  $('[data-widget=histogram]').each(histogram_widget);
  $('[data-widget=bar-graph]').each(bar_graph);
  $('[data-widget=distribution]').each(distributionWidget);
}

function bar_graph(){
  let me = $(this);
  me.width(mkstr("%s%", [me.data('width') * 100]));
}

function ajaxReplace(elem, url){
    return Promise.resolve(
        $.ajax({
            url:url,
            datatype: 'html'
        }))
        .then(function(data){
            if(elem.data('permission') == 'authorized'){
                $('body').on('click', '.backdrop', clearModal);
            }
            if(elem.hasClass('modal')){
                elem = elem.replaceWith(`
                    <div class="modal-contain poll-modal active" data-mode="poll">
                        <div class="backdrop"></div>
                        <div class="modal-main modal active openModal" role="dialog" aria-modal="true">
                            ${data}
                        </div>
                    </div>
                `);
                initializeSurveyScripts();
                modalAccessibility();
            } else {
                elem = elem.replaceWith(data);
            }
            console.log('Loading Finished', elem, Date());
            return elem;
        }).catch(function(err){
            console.log(err.status, err.statusText);
            return elem.remove();
        }
    );
}

function distributionWidget(){
    let me = $(this);
    let loader = me.prepend(get_loading_indicator());

    let container_width = me.width();
    let requested_width = me.data('width');
    let downloadURL = me.data('name');

    let margin = {top: 70, right: 30, bottom: 50, left: 50},
    width = requested_width < container_width || container_width == 0 ? requested_width : container_width - margin.left - margin.right,
    height = me.data('height') - margin.top - margin.bottom,
    ratio = height / requested_width;

    let x = d3.scaleBand()
       .rangeRound([0, width])
       .paddingInner(.3)
       .paddingOuter(.3);

    let y = d3.scaleLinear()
       .range([height, 0]);

    let xAxis = d3.axisBottom().scale(x);

    let yAxis = d3.axisLeft().scale(y).ticks(5);

    let gridYAxis = d3.axisLeft()
       .scale(y).ticks(5).tickSize(-width, 0, 0)
       .tickFormat("");

    let tip = d3.tip()
    .attr('class', 'd3-tip')
    .offset([-10, 0])
    .html(function(d) {
      return d.value + "%";
    });

    me.html('');
    let chart = d3.select(me[0]).append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr("data-name", downloadURL)
        .append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    chart.call(tip);

    $.ajax({
        url: me.data('src'),
    }).done(function(data){
      let distribution = data.distribution.map(grade => {
          return {'label': grade.label, value: grade.percent}
      });

     x.domain(distribution.map(function(d) { return d.label; }));
     y.domain([0, d3.max(distribution, function(d) { return d.value; })]);

     me.find('.loading-container').remove();
     chart.append("g")
        .attr("class", "axis x-axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);

     if(!data.curved) {
     chart.selectAll('.x-axis text')
        .data(data.distribution)
        .append('tspan')
            .attr("y", "2.6em")
            .attr("x", 0)
            .text(function(grade) { return grade.min_value + '-' + grade.max_value });
     }

     chart.append("g")
         .attr("class", "grid")
         .call(gridYAxis);

     d3.selectAll('.grid .tick')
         .attr('fill', '#eee')
         .attr('opacity', '0.5');

     d3.selectAll('.widget .grid path')
         .attr('stroke-width', 0);

     chart.append("g")
          .attr("class", "axis y-axis")
          .call(yAxis);

     chart.append("text")
      .attr("class", "axis-label x-axis-label")
      .attr("text-anchor", "middle")
      .attr("font-family", '"Gotham SSm A","Gotham SSm B",Helvetica,Arial,sans-serif')
      .attr("x", width / 2)
      .attr("y", height + 45)
      .text("Class Performance");

     chart.append("text")
         .attr("class", "graph-header")
         .attr("text-anchor", "middle")
         .attr("font-family", '"Gotham SSm A","Gotham SSm B",Helvetica,Arial,sans-serif')
         .attr("x", width / 2)
         .attr("y", -55)
         .text(data.label);

     chart.append("text")
        .attr("class", "axis-label y-axis-label")
        .attr("text-anchor", "middle")
        .attr("font-family", '"Gotham SSm A","Gotham SSm B",Helvetica,Arial,sans-serif')
        .attr("y", -45)
        .attr("dy", ".75em")
        .attr('x',-height/2)
        .attr("transform", "rotate(-90)")
        .text("Percentage of Students");

       var bar = chart.selectAll(".bar")
          .data(distribution)
        .enter().append("rect")
          .attr("class", "bar")
          .attr("fill", "#3e9aad")
          .attr("x", function(d) { return x(d.label); })
          .attr("y", function(d) { return y(d.value); })
          .attr("height", function(d) { return height - y(d.value); })
          .attr("width", x.bandwidth())
          .on('mouseover', tip.show)
           .on('mouseout', tip.hide);

      bar.append("text")
          .attr("x", x.bandwidth() / 2)
          .attr("y", function(d) { return y(d.value) + 3; })
          .attr("dy", ".75em")
          .attr("font-family", '"Gotham SSm A","Gotham SSm B",Helvetica,Arial,sans-serif')
          .text(function(d) { return d.value; });

      if(exists(data.student.grade)){
          let activeBar = bar.filter(function(d) { return d.label == data.student.grade; })
           .attr("class", "bar active");

          let highlight = {'padding': x.bandwidth() * x.paddingInner(),
                           'x': x(data.student.grade)}

          let percentileEnding = "TH";

          if (data.percentile){
              let percentileText = String(data.percentile);
              if (percentileText[percentileText.length - 1] == '1'){
                  percentileEnding = 'st';
              }
              else if (percentileText[percentileText.length - 1] == '2'){
                  percentileEnding = 'nd';
              }
              else if (percentileText[percentileText.length - 1] == '3'){
                  percentileEnding = 'rd';
              }
          }

          let highlightText = data.percentile ? "YOU'RE AT THE " + data.percentile + percentileEnding : "Your Grade";

          chart.append('rect')
             .attr('class', 'highlight')
             .attr('fill', '#76ccd1')
             .style('opacity', '0.4')
             .attr("y", -18)
             .attr("height", height + 18)
             .attr("width", x.bandwidth() + highlight.padding * 2)
             .attr("x", highlight.x - highlight.padding);

          highlightText = chart.append('text')
             .attr('class', 'highlight-label')
             .attr('x', highlight.x + x.bandwidth()/2)
             .attr('y', -45)
             .attr('dy', '.75em')
             .attr('fill', 'teal')
             .attr('font-size', '12px')
             .attr('font-weight', 600)
             .attr('text-anchor', 'middle')
             .attr("font-family", '"Gotham SSm A","Gotham SSm B",Helvetica,Arial,sans-serif')
             .text(highlightText);

          highlightText.append('tspan')
            .attr('x', highlight.x + x.bandwidth()/2)
            .attr('dy', '1.2em')
            .text('PERCENTILE')
      }

      if(exists(data.median)){
          let medianLineX = x(data.median.label);

          let adjustNumerator = Number(data.median.percent) - Number(data.median.min_value);
          let adjustDenominator = Number(data.median.max_value) - Number(data.median.min_value);

          medianLineX += adjustNumerator / adjustDenominator * x.bandwidth();

          if(!data.noline) {
          chart.append('line')
            .attr('class', 'median-line')
            .style('stroke-width', 1)
            .style('stroke', 'red')
            .attr('x1', medianLineX)
            .attr('y1', 0)
            .attr('x2', medianLineX)
            .attr('y2', height)
          }

          let medianLabel = data.curved ? data.median.grade_label : data.median.label;

          let medianText = chart.append('text')
            .attr('class', 'median-text')
            .attr('fill', 'red')
            .attr('font-size', '12px')
            .attr('font-weight', 600)
            .attr('x', medianLineX)
            .attr('y', - 6)
            .attr('text-anchor', 'middle')
            .attr("font-family", '"Gotham SSm A","Gotham SSm B",Helvetica,Arial,sans-serif')
            .text("STEM major median: " + medianLabel)

      }

    });

}

function histogram_widget(){
  var me = $(this);
  var loader = me.prepend(get_loading_indicator());

  var container_width = me.width();
  var requested_width = me.data('width');

  var margin = {top: 50, right: 30, bottom: 50, left: 50},
  width = requested_width < container_width || container_width == 0 ? requested_width : container_width - margin.left - margin.right,
  height = me.data('height') - margin.top - margin.bottom,
  ratio = height / requested_width;

  var x = d3.scaleBand()
     .rangeRound([0, width])
     .paddingInner(.3)
     .paddingOuter(.3);

  var y = d3.scaleLinear()
     .range([height, 0]);

  var xAxis = d3.axisBottom().scale(x);

  var yAxis = d3.axisLeft().scale(y).ticks(5);

  var gridYAxis = d3.axisLeft()
     .scale(y).ticks(5).tickSize(-width, 0, 0)
     .tickFormat("");

  var tip = d3.tip()
  .attr('class', 'd3-tip')
  .offset([-10, 0])
  .html(function(d) {
    return d.value + "%";
  });

  me.html('');
  var chart = d3.select(me[0]).append("svg")
  .attr("width", width + margin.left + margin.right)
  .attr("height", height + margin.top + margin.bottom)
  .append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  chart.call(tip);
  $.ajax({url:me.data('src')}).done(function(data){
    var distribution = [];
    for(key in data.distribution){
      distribution.push({'label': key, value: data.distribution[key]});
    }

   x.domain(distribution.map(function(d){return d.label;}));
   y.domain([0, d3.max(distribution, function(d) { return d.value; })]);
   me.find('.loading-container').remove();
   chart.append("g")
      .attr("class", "axis x-axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

   chart.append("g")
       .attr("class", "grid")
       .call(gridYAxis);

   chart.append("g")
        .attr("class", "axis y-axis")
        .call(yAxis);

   chart.append("text")
    .attr("class", "axis-label x-axis-label")
    .attr("text-anchor", "middle")
    .attr("x", width/2)
    .attr("y", height + 40)
    .text("Class Performance");

   chart.append("text")
       .attr("class", "graph-header")
       .attr("text-anchor", "middle")
       .attr("x", width/2)
       .attr("y", -35)
       .text(data.label);

   chart.append("text")
      .attr("class", "axis-label y-axis-label")
      .attr("text-anchor", "middle")
      .attr("y", -45)
      .attr("dy", ".75em")
      .attr('x', -height/2)
      .attr("transform", "rotate(-90)")
      .text("Percentage of Students");

     var bar = chart.selectAll(".bar")
        .data(distribution)
      .enter().append("rect")
        .attr("class", "bar")
        .attr("x", function(d) { return x(d.label); })
        .attr("y", function(d) { return y(d.value); })
        .attr("height", function(d) { return height - y(d.value); })
        .attr("width", x.bandwidth())
        .on('mouseover', tip.show)
         .on('mouseout', tip.hide);

    bar.append("text")
        .attr("x", x.bandwidth() / 2)
        .attr("y", function(d) { return y(d.value) + 3; })
        .attr("dy", ".75em")
        .text(function(d) { return d.value; });

    let svgElement = me.find('svg');
    let distributionOutput = '';
    let distributionCount = Object.keys(data.distribution).length;

    for(const [index, [key, value]] of Object.entries(Object.entries(data.distribution))){
      if(distributionCount == index + 1){
        distributionOutput += `and ${key}: ${value}% of students`;
      } else {
        distributionOutput += `${key}: ${value}% of students, `;
      }
    }
    if(exists(data.student.grade)){
        var activeBar = bar.filter(function(d) { return d.label == data.student.grade; })
         .attr("class", "bar active");

        var highlight = {
            'padding': x.bandwidth() * x.paddingInner(),
            'x': x(data.student.grade)
        }

        chart.append('rect')
           .attr('class', 'highlight')
           .attr("y", -10)
           .attr("height", height+10)
           .attr("width", x.bandwidth() + highlight.padding*2)
           .attr("x", highlight.x - highlight.padding);

        chart.append('text')
           .attr('class', 'highlight-label')
           .attr('x', highlight.x + x.bandwidth()/2)
           .attr('y', -25)
           .attr('dy', '.75em')
           .text('Your Grade');
        svgElement.prepend(`
           <desc id="histogramDesc">
                Your grade: ${data.student.grade}; class distribution: ${distributionOutput}
           </desc>
        `);
    } else {
        svgElement.prepend(`
            <desc id="histogramDesc">
                Class distribution: ${distributionOutput}
            </desc>
        `);
    }
    svgElement
        .prepend(`
            <title id="histogramTitle">Exam Results: Grade Distribution</title>
        `)
        .attr("aria-labelledby", "histogramTitle histogramDesc");
  });
}

export {
    loadContent
}
