<template>
    <div class="image-select-modal">
    <div class="backdrop"></div>
    <div class="modal active">
      <div class="content modal-info modal-header">
          <h2>Select Image</h2>
          <button class="btn btn-close btn-circle"
              type="button" @click="toggleImageSelectOpenState()"
              aria-label="Cancel">
          </button>
      </div>
      <div class="survey_question image-directory">
          <label class="survey_prompt">
              Please select an image that is most applicable to this testimonial
          </label>
          <div class="survey_responses">
              <ul class="image-list">
                  <li
                      v-for="image in imageListData"
                      :key="image.file_name"
                      @click="selectImage(image)">
                      <label>
                          <input class="sr-only" type="radio" :id="image.file_name" name="message-img">
                          <img :src="image.file_path" />
                      </label>
                  </li>
              </ul>
          </div>
          <button class="btn" @click="saveSelect()">Save Image Selection</button>
      </div>
      <div class="image-select-own">
          <div class="separator">- OR -</div>
          <div v-if="selectedFile && !ExistedFlag" class="image-preview">
              <div class="display-image">
                  <img :src="selectedFile" alt="Uploaded image" />
              </div>
              <button class="btn btn-seafoam" @click="triggerUploadNew()">Update Uploaded Image</button>
          </div>
          <div v-else>
              <button class="btn btn-seafoam" @click="triggerUploadNew()">Upload Your Own</button>
          </div>
      </div>
    </div>  
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useIconPickerStore } from '../../stores/icon-picker.js';
import { useImageSelectStore } from '../../stores/image-select.js';

export default({
    name: 'IconList',
    methods: {
      ...mapActions(useIconPickerStore, [
        'setExistedFlag',
        'setTriggerUpload',
      ]),
      ...mapActions(useImageSelectStore, [
        'displaySelectedImage',
        'loadImageData',
        'toggleImageSelectOpenState',
        'updateImageData',
      ]),
      selectImage (image) {
        this.selectedImage = image;
      },
      saveSelect() {
        this.displaySelectedImage(this.selectedImage);
        this.setExistedFlag(true);
        this.toggleImageSelectOpenState();
      },
      triggerUploadNew() {
        this.setTriggerUpload(true);
      },
    },
    computed: {
      ...mapState(useImageSelectStore, [
        'imageListData',
      ]),
      ...mapState(useIconPickerStore, [
        'ExistedFlag',
        'selectedFile',
      ])
    },
    mounted() {
      this.loadImageData();
    }
});
</script>
