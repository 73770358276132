<template>
    <div>
        <modal
            v-if="showCreateModal"
            :modalClasses="'todo-modal active'"
            v-on:modal-cancel="updateToDoShowCreateModal()">
            <focus-trap>
                <div>
                    <div class="modal-header">
                        <h2>Add Your Own To-Do Item(s)</h2>
                        <button
                            ref="firstFocusableElement"
                            class="btn modal-close"
                            type="button"
                            aria-label="Close modal"
                            @click="updateToDoShowCreateModal()">
                            <svg class="modal-icon icon-navy">
                                <use :xlink:href="exitIcon"></use>
                            </svg>
                        </button>
                    </div>
                    <FormKit
                      v-model="formData"
                      @submit="saveItem"
                      form-class="survey"
                      type="form"
                      :actions="false"
                    >
                        <FormKit
                            type="group"
                            name="todoitems"
                            :repeatable="true"
                          >
                            <FormKit type="hidden" name="account_item" :value="[]" />
                            <FormKit
                                type="hidden"
                                name="todo_list"
                                :value="todoListData.id" />
                                <FormKit
                                    type="hidden"
                                    name="staff_created"
                                    :value="false" />
                            <FormKit
                                outer-class="survey_question"
                                label-class="survey_prompt"
                                name="title"
                                :errors="this.formErrors['title']"
                                validation="required"
                                label="Title">
                                <template #label="context">
                                  <div :class="context.classes.label" :for="context.id">
                                    Title <span class="required-item">*</span>
                                  </div> 
                                </template>
                            </FormKit>
                            <FormKit
                                outer-class="survey_question"
                                label-class="survey_prompt"
                                input-class="rich-text"
                                inner-class="survey_responses"
                                type="textarea"
                                name="description"
                                id="todo_description"
                                label="Description" />
                            <FormKit
                                outer-class="survey_question"
                                label-class="survey_prompt"
                                input-class="datepicker"
                                type="text"
                                name="due_date"
                                :errors="this.formErrors['due_date']"
                                label="Due Date" />
                            <FormKit
                                outer-class="survey_question checkbox-tags"
                                legend-class="survey_prompt"
                                fieldset-class="survey_responses"
                                options-class="survey_responses"
                                type="checkbox"
                                :options="coachToDoItemTags"
                                label="Tags"
                                name="item_tag_ids" />
                        </FormKit>
                        <div class="button-block">
                            <button
                                class="btn btn-cancel btn-light-grey"
                                type="button"
                                @click="updateToDoShowCreateModal()">
                                Cancel
                            </button>
                            <FormKit outer-class="formulate-btn" type="submit" label="Save" />
                        </div>
                    </FormKit>
                </div>
            </focus-trap>
        </modal>
        <modal
            v-if="showUpdateModal"
            modalClasses="todo-modal todo-edit active"
            @modal-cancel="updateToDoShowUpdateModal()">
            <focus-trap>
                <div>
                    <div class="modal-header">
                        <h2>Edit Your To-Do Item</h2>
                        <button
                            ref="firstFocusableElement"
                            class="btn modal-close"
                            type="button"
                            aria-label="Close modal"
                            @click="updateToDoShowUpdateModal()">
                            <svg class="modal-icon icon-navy">
                                <use :xlink:href="exitIcon"></use>
                            </svg>
                        </button>
                    </div>
                    <FormKit
                        v-if="activeItemData"
                        v-model="activeItemData"
                        type="form"
                        form-class="survey survey-edit"
                        :actions="false"
                        @submit="editItem"
                    >
                        <div class="block-header">
                            <h3>To-Do Item</h3>
                            <button class="btn btn-delete" type="button" @click="deleteItem(activeItemData.id)">Delete</button>
                        </div>
                        <FormKit type="hidden" name="todo_list" />
                        <FormKit type="hidden" name="staff_created" />
                        <FormKit type="hidden" name="account_item" />
                        <FormKit
                            outer-class="survey_question"
                            label-class="survey_prompt"
                            name="title"
                            :errors="this.formErrors['title']"
                            validation="required"
                            label="Title">
                            <template #label="context">
                              <div :class="context.classes.label" :for="context.id">
                                Title <span class="required-item">*</span>
                              </div> 
                            </template>
                        </FormKit>
                        <FormKit
                            outer-class="survey_question"
                            label-class="survey_prompt"
                            inner-class="survey_responses"
                            input-class="rich-text"
                            id="todo_description"
                            type="textarea"
                            name="description"
                            label="Description" />
                        <FormKit
                            outer-class="survey_question"
                            label-class="survey_prompt"
                            input-class="datepicker"
                            type="text" 
                            name="due_date"
                            :errors="this.formErrors['due_date']"
                            label="Due Date" />
                        <FormKit
                            outer-class="survey_question"
                            legend-class="survey_prompt"
                            fieldset-class="survey_responses"
                            options-class="survey_responses"
                            type="checkbox"
                            label="Tags"
                            name="item_tag_ids"
                            :options="coachToDoItemTags" />
                        <div class="button-block">
                            <button
                                class="btn btn-cancel"
                                type="button"
                                @click="updateToDoShowUpdateModal()">
                                Cancel
                            </button>
                            <FormKit outer-class="formulate-btn" type="submit" label="Save" />
                        </div>
                    </FormKit>
                </div>
            </focus-trap>
        </modal>
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTodoStore } from '../../stores/todo.js';

import Modal from "../generic/Modal.vue";
import { FocusTrap } from "focus-trap-vue";
import { exitIcon } from "../../images.js";
import { FormKitMessages } from '@formkit/vue'

export default {
  name: "ToDoModal",
  data() {
    return {
      formData: {},
      index: 0,
      exitIcon: exitIcon,
      formErrors: {},
    };
  },
  components: {
    Modal,
    FocusTrap,
  },
  methods: {
    ...mapActions(useTodoStore, [
      'loadTodoListData',
      'createToDoItem',
      'updateToDoItem',
      'deleteToDoItem',
      'updateSelectedItemData',
      'updateShowCreateModal',
      'updateShowUpdateModal',
    ]),
    setupWidgets() {
      setup_widgets();
      CKEDITOR.replaceAll(CKEDITOR.replaceClass);
    },
    deleteItem(itemId) {
      this.deleteToDoItem(itemId).then((response) => {
        this.loadTodoListData();
        this.updateShowUpdateModal();
      });
      this.formErrors = {};
    },
    cleanData(data) {
      data.due_date = this.cleanDate(data.due_date);
      data.description = this.getDescription();
      return data;
    },
    cleanDate(dateString) {
      if(dateString != $('.datepicker').val()) {
        dateString = $('.datepicker').val();
      }
      let formattedDate = this.$luxonDateTime.fromFormat(dateString, 'MM/dd/yyyy')
      formattedDate = formattedDate.isValid ? formattedDate.toISODate() : dateString;
      return formattedDate ? formattedDate : null;
    },
    getDescription() {
      CKEDITOR.instances.todo_description.updateElement();
      return CKEDITOR.instances.todo_description.getData();
    },
    saveItem() {
      let data = this.cleanData(this.formData.todoitems);
      this.createToDoItem(data).then((response) => {
        if (response.errors){
          this.formErrors = response.errors;
        } else {
          this.loadTodoListData();
          this.formErrors = {}
        }
      });
    },
    editItem() {
      let data = this.cleanData(this.activeItemData);
      this.updateToDoItem(data).then((response) => {
         if (response.errors){
          this.formErrors = response.errors;
        } else {
          this.loadTodoListData();
          this.formErrors = {}
        }
      });
    },
    focusWithinModal() {
      this.$refs.firstFocusableElement.focus();
    },
    updateToDoShowCreateModal() {
      this.updateShowCreateModal();
      this.formErrors = {};
    },
    
    updateToDoShowUpdateModal() {
      this.updateShowUpdateModal();
      this.formErrors = {};
    },
    formateDate(date) {
      const formattedDate = this.$luxonDateTime.fromFormat(date, 'yyyy-MM-dd');
      return formattedDate.isValid ? formattedDate.toFormat('MM/dd/yyyy') : date;
    }
  },
  computed: {
    ...mapState(useTodoStore, [
      'todoListData',
      'showCreateModal',
      'newItemData',
      'coachToDoItemTags',
      'selectedItemData',
      'showUpdateModal',
    ]),
    activeItemData: {
      get() {
        if (this.selectedItemData.due_date) {
          this.selectedItemData.due_date = this.formateDate(this.selectedItemData.due_date);
        }
        return this.selectedItemData;
      },
      set(value) {
        this.updateSelectedItemData(value);
      },
    },
  },
  watch: {
    showCreateModal() {
      if(this.showCreateModal) {
        this.$nextTick(() => {
            this.focusWithinModal();
            this.setupWidgets();
        });
      }
    },
    showUpdateModal() {
      if(this.showUpdateModal) {
        this.$nextTick(() => {
            this.focusWithinModal();
            this.setupWidgets();
        });
      }
    },
  },
};
</script>
