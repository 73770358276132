<template>
    <modal
        :modalClasses="'active'"
        v-if="isOpen"
        @modal-cancel="toggleImageSelectOpenState()">
        <imagelist></imagelist>
    </modal>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useImageSelectStore } from '../../stores/image-select.js';

import Modal from '../generic/Modal.vue';
import ImageList from './ImageList.vue';

export default({
  name: 'ImageSelectModal',
  data () {
    return {}
  },
  components: {
    modal: Modal,
    imagelist: ImageList,
  },
  methods: {
    ...mapActions(useImageSelectStore, [
      'toggleImageSelectOpenState',
    ]),
  },
  computed: {
    ...mapState(useImageSelectStore, [
      'isOpen'
    ]),
  }
});

</script>