<template>
    <div>
        <div class="loading-container" v-if="loading">
            <div class="loading-indicator"></div>
            <span class="loading-text">Loading...</span>
        </div>
        <template v-else>
            <template v-if="!isOpenForm">
                <!-- <div class="card-header">
                    <h2>Student Insights</h2>
                </div> -->
                <div class="card">
                    <cohort-list
                        @delete-instance="deleteCohortInstance"
                        @edit-instance="editCohort"
                        @reload="resetInterface"
                        @showCohortForm="showCohortForm = true"
                        :cohorts="cohorts"
                    />
                    <cohort-group-list
                        @delete-instance="deleteCohortGroupInstance"
                        @edit-instance="editCohortGroup"
                        @showGroupForm="showGroupForm = true"
                    />
                </div>
            </template>
            <modal
                :modalClasses="'active form-modal'"
                v-if="showGroupForm"
                @modal-cancel="resetInterface">
                <cohort-group-form
                    :instanceData="instance"
                    :formErrors="formErrors"
                    :editing="editing"
                    @save-instance="saveCohortGroupInstance"
                    @cancel-form="resetInterface"
                    @delete-instance="deleteCohortGroupInstance"
                />
            </modal>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTailoringStore } from './stores/tailoring.js'

import { loadingMixin } from "../../mixins/loading.js";

import CohortGroupList from './CohortGroupList.vue';
import CohortList from './CohortList.vue';
import CohortGroupForm from './CohortGroupForm.vue';
import CohortForm from './CohortForm.vue';
import LogicRuleForm from './LogicRuleForm.vue';
import Modal from '../../components/generic/Modal.vue';

export default {
  name: 'CohortOverview',
  mixins: [
    loadingMixin,
  ],
  components: {
    CohortGroupList,
    CohortList,
    CohortGroupForm,
    CohortForm,
    LogicRuleForm,
    Modal
  },
  props: {
    instanceObject: {
      type: Object,
      default: () => {
        return {}
      },
    }
  },
  data () {
    return {
      showCohortForm: false,
      showGroupForm: false,
      instance: this.instanceObject,
      formErrors: {},
    };
  },
  computed: {
    editing () {
      return !!this.instance.id;
    },
    ...mapState(useTailoringStore, [
      'cohorts',
    ]),
    isOpenForm(){
        return (this.showGroupForm || this.showCohortForm)
    }
  },
  methods: {
    ...mapActions(useTailoringStore, [
      'getCohortGroupList',
      'getCohortList',
      'getCohortData',
      'getMTSDictionaryData',
      'saveCohortGroup',
      'deleteCohortGroup',
      'saveCohort',
      'deleteCohort',
      'getEnrollmentCount',
    ]),
    editCohortGroup(instanceData) {
      this.instance = instanceData;
      this.showGroupForm = true;
    },
    saveCohortGroupInstance(instanceData) {
      this.saveCohortGroup(instanceData).then((response) => {
        if (response.errors) {
          this.formErrors = response.errors;
        } else {
          this.resetInterface();
          this.closeForm();
        }
        this.loading = false;
      });
      this.loading = true;
    },
    deleteCohortGroupInstance(instanceData) {
      this.deleteCohortGroup(instanceData).then(() => {
        this.resetInterface();
      });
      this.closeForm();
    },
    editCohort(instanceData) {
      this.instance = instanceData;
      this.showCohortForm = true;
    },
    saveCohortInstance(instanceData) {
      this.saveCohort(instanceData).then(() => {
        this.resetInterface();
      });
      this.closeForm();
    },
    deleteCohortInstance(instanceData) {
      this.deleteCohort(instanceData).then(() => {
        this.resetInterface();
      });
      this.closeForm();
    },
    resetInterface() {
      this.loadInterfaceData();
      this.closeForm();
    },
    closeForm() {
      this.showCohortForm = this.showGroupForm = false;
      this.instance = {};
      this.formErrors = {};
      this.$emit('modal-cancel');
    },
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([
        this.getEnrollmentCount(),
        this.getCohortGroupList(),
        this.getCohortList(),
        this.getMTSDictionaryData(),
      ]).then(() => {
        this.stopLoading();
      });
    },
  },
  created () {
    this.loadInterfaceData();
  },
}

</script>
