<template>
    <div class="loading-container" v-if="loading">
        <div class="loading-indicator"></div>
        <span class="loading-text">Loading...</span>
    </div>
    <template v-else>
        <div class="count-container">
            <div class="count">
                <div class="icon-container container-white">
                    <svg class="card-icon">
                        <use :href="`/static/img/IconSprite.svg#${content_tag}`"></use>
                    </svg>
                </div>
                <span>{{ completed }}</span>
            </div>
            <p>
                Of <b><i>{{ total }} {{ contentTypeLabel }}</i></b> {{ complete_type }}
            </p>
        </div>
        <div v-if="show_progress_bar"
            class="bar-graph-container barGraphContainer" :class="bar_color"
            role="presentation">
            <div class="graph">
                <div class="bar bar-diag bar-full unread"></div>
                <div class="bar bar-solid read"
                    :data-percent="completed_percentage">
                </div>
            </div>
            <p>
                <b>{{ completed }}</b> {{ complete_label }} | <b>{{ incomplete }}</b>
                {{ incomplete_label }}
            </p>
        </div>
    </template>
</template>

<script>
import { loadingMixin } from "../../mixins/loading.js";

export default {
  name: "MessageDetails",
  mixins: [loadingMixin],
  components: {},
  props: {
    complete_type: {
      type: String,
    },
    content_type: {
      type: String,
    },
    complete_label: {
      type: String,
    },
    incomplete_label: {
      type: String,
    },
    show_progress_bar: {
      type: Boolean,
    },
    completed: {
      type: Number,
    },
    completed_percentage: {
      type: String,
    },
    incomplete: {
      type: Number,
    },
    total: {
      type: Number,
    },
    content_tag: {
      type: String,
    },
    bar_color: {
      Type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([]).then(() => {
        this.stopLoading();
      });
    },
  },
  computed: {
    contentTypeLabel() {
      return `${this.content_type}${ this.total === 1 ? '' : 's'}`;
    },
  },
  created() {
    this.loadInterfaceData();
  },
};
</script>
