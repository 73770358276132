import axios from "axios";
import { defineStore } from 'pinia';

import {
  coachBaseUrl,
  todoListDataUrl,
  createTodoItemUrl,
  createAccountTodoItemUrl,
  todoItemTagsUrl
} from "../urls.js";

const coachInfo = coachBaseUrl.match(location.pathname);
const initialState = {
  todoListData: {},
  todoListDataUrlBase: coachInfo ? todoListDataUrl.stringify(coachInfo) : null,
  todoListDataUrl: coachInfo ? todoListDataUrl.stringify(coachInfo) : null,
  createTodoItemUrl: coachInfo ? createTodoItemUrl.stringify(coachInfo) : null,
  createAccountTodoItemUrl: coachInfo ? createAccountTodoItemUrl.stringify(coachInfo) : null,
  todoItemTagsUrl: coachInfo ? todoItemTagsUrl.stringify(coachInfo) : null,
  currentPage: '',
  currentToDoList: null,
  showNextButton: false,
  showPrevButton: false,
  showCreateModal: false,
  coachToDoItemTags: [],
  selectedItemData: {},
  showUpdateModal: false,
};

export const useTodoStore = defineStore('todo', {
  state: () => initialState,
  actions: {
    updateTodoListData (payload) {
      this.todoListData = payload;
    },
    updateSelectedItemData (payload) {
      this.selectedItemData = payload;
    },
    updateCurrentPage (currentpage) {
      this.currentPage = currentpage;
    },
    updateCurrentToDoList (currentToDoList) {
      this.currentToDoList = currentToDoList;
    },
    updateTodoListUrl () {
      this.todoListDataUrl = `${this.todoListDataUrlBase}?page=${this.currentPage}`;
    },
    updateShowNextButton (payload) {
      this.showNextButton = payload;
    },
    updateShowPrevButton (payload) {
      this.showPrevButton = payload;
    },
    updateCoachToDoItemTags (payload) {
      this.coachToDoItemTags = payload;
    },
    updateShowUpdateModal  () {
      this.showUpdateModal = this.showUpdateModal === false;
    },
    updateShowCreateModal  () {
      this.showCreateModal = this.showCreateModal === false;
    },
    async loadTodoListData() {
      return axios.get(this.todoListDataUrl).then((response) => {
        this.updateTodoListData(response.data.results[0]);
        this.updateCurrentPage(parseInt(response.data.page));
        if (response.data.next) {
          this.updateShowNextButton(true);
        } else {
          this.updateShowNextButton(false);
        }
        if (response.data.previous) {
          this.updateShowPrevButton(true);
        } else {
          this.updateShowPrevButton(false);
        }
      });
    },
    async createToDoItem (data) {
      return axios.post(this.createTodoItemUrl, data).then((response) => {
        this.updateShowCreateModal();
        return response;
      }).catch((error) => {
        return { errors: error.response.data };
      });
    },
    async deleteToDoItem (data) {
      let url = `${this.createTodoItemUrl}/${data}`;
      return axios.delete(url);
    },
    async updateToDoItem (data) {
      delete data["due_date_short"];
      let url = `${this.createTodoItemUrl}/${data.id}`;
      return axios.put(url, data).then((response) => {
          this.updateShowUpdateModal();
          return response;
      }).catch((error) => {
        return { errors: error.response.data };
      });
    },
    async completeAccountToDoItem (data) {
      return axios.post(this.createAccountTodoItemUrl, data);
    },
    async loadTodoItemTagsData () {
      return axios.get(this.todoItemTagsUrl).then((response) => {
          this.updateCoachToDoItemTags(response.data);
      });
    },
  },
});
