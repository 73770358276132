import axios from "axios";

export const genericMixin = {
  getters: {
    urlParams: () => instance => {
      return {
        ...instance,
        objectId: instance.id,
      };
    },
  },
  actions: {
    // Generic Actions //
    async loadObjectList ({ mutation, urls, urlParams = {}, params = {} }) {
      const url = urls.list.stringify(urlParams);
      return axios.get(url, { params }).then((response) => {
        mutation({ data: response.data });
      });
    },
    async fetchNewInstanceData ({ createUrl, urlParams = {}}) {
      let url = createUrl.stringify(urlParams);
      return axios.get(url).then((response) => response.data);
    },
    async fetchInstanceData ({ data, urls, urlParams = {} }) {
      let apiUrlPattern = data.id ? urls.update : urls.create;
      let url = apiUrlPattern.stringify(urlParams);
      return axios.get(url).then((response) => response.data);
    },
    async saveInstanceData ({ data, urls, urlParams = {} }) {
      let apiUrlPattern = data.id ? urls.update : urls.create;
      let url = apiUrlPattern.stringify(urlParams);
      return (data.id ? axios.put : axios.post)(url, data).then(
        (response) => response.data
      ).catch((error) => {
        return { errors: error.response.data };
      });
    },
    async deleteInstanceData ({ data, urls, urlParams = {} }) {
      let url = urls.update.stringify(urlParams);
      return axios.delete(url, data).then(
        (response) => response.data
      ).catch((error) => {
        return { errors: error.response.data };
      });
    },
    async fetchInstanceDetailsData ({ data, urls, urlParams = {} }) {
      const url = urls.details.stringify(urlParams);
      return axios.get(url).then((response) => response.data);
    },
    // End Generic Actions //
  },
};
