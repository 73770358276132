<template>
  <div class="image-preview" id="selectImage">
    <div class="display-image">
      <img
        v-if="imageData && !selectImageURL"
        :src="imageData.default_image_path"
        alt="Image preview"
      />
      <img
        v-else-if="selectImageURL"
        :src="selectImagePath"
        alt="Image preview"
      />
    </div>
    <button
      class="btn"
      @click="toggleImageSelectOpenState()"
      type="button"
      aria-label="Update presentation image"
    >
      Update Image
    </button>
    <div class="hidden-field">
      <input
        type="hidden"
        :name="imageData.name"
        maxlength="200"
        :id="imageData.id"
        v-if="selectImageURL"
        v-model="selectImageURL"
      />
      <input
        type="hidden"
        :name="imageData.name"
        maxlength="200"
        :id="imageData.id"
        v-else-if="imageData && !selectImageURL"
        v-model="imageData.default_image"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useImageSelectStore } from '../../stores/image-select.js';

export default {
  name: "ImageSelectItem",
  data() {
    return {
      modalOpen: false
    };
  },
  methods: {
    ...mapActions(useImageSelectStore, [
        'toggleImageSelectOpenState',
        'updateImageFieldData',
        'loadImageFieldData',
      ])
  },
  computed: {
    ...mapState(useImageSelectStore, [
      'isOpen',
      'imageField',
      'selectImageURL',
      'selectImagePath'
    ]),
    imageData() {
      let imageData = this.imageField;
      return imageData?.length ? imageData[0] : null;
    }
  },
  mounted () {
    this.loadImageFieldData();
  }
};
</script>