import UrlPattern from 'url-pattern';

export const adminCoachBaseUrl = new UrlPattern('/Dashboard/Coach/:coachId/*');

export const cohortManagerUrl = new UrlPattern('/Dashboard/Coach/:coachId/cohort-manager');
export const cohortDetailUrl = new UrlPattern('/Dashboard/Coach/:coachId/cohort-manager/:cohortId/details');
export const cohortAddPageUrl = new UrlPattern('/Dashboard/Coach/:coachId/cohort-manager/add-cohort');

export const cohortGroupListUrl = new UrlPattern('/api/v1/coach/:coachId/cohort/groups');
export const cohortGroupUpdateUrl = new UrlPattern('/api/v1/coach/:coachId/cohort/groups/:cohortGroupId/');

export const mtsdictionaryDataUrl = new UrlPattern('/api/v1/coach/:coachId/mtsdictionarydata');

export const cohortGroupUrls = {
  list: cohortGroupListUrl,
  create: cohortGroupListUrl,
  update: cohortGroupUpdateUrl,
  detail: cohortGroupUpdateUrl,
  delete: cohortGroupUpdateUrl,
}

export const cohortListUrl = new UrlPattern('/api/v1/coach/:coachId/cohort');
export const cohortUpdateUrl = new UrlPattern('/api/v1/coach/:coachId/cohort/:cohortId/');

export const cohortUrls = {
  list: cohortListUrl,
  create: cohortListUrl,
  update: cohortUpdateUrl,
  detail: cohortUpdateUrl,
  delete: cohortUpdateUrl,
}

export const cohortRuleListUrl = new UrlPattern('/api/v1/coach/:coachId/cohort/:cohortId/rules');
export const cohortRuleUpdateUrl = new UrlPattern('/api/v1/coach/:coachId/cohort/:cohortId/rules/:ruleId/');

export const cohortRuleUrls = {
  list: cohortRuleListUrl,
  create: cohortRuleListUrl,
  update: cohortRuleUpdateUrl,
  detail: cohortRuleUpdateUrl,
  delete: cohortRuleUpdateUrl,
}

export const cohortUserListUrl = new UrlPattern('/api/v1/coach/:coachId/cohort/:cohortId/users');
export const coachEnrollmentDetailsUrl = new UrlPattern('/api/v1/coach/:coachId/enrollment-details')
export const updateCohortMembershipUrl = new UrlPattern('/Dashboard/Coach/:coachId/cohort-membership-update/:cohortId');
export const cohortTailoringDistributionUrl = new UrlPattern('/api/v1/coach/:coachId/cohort/:cohortId/tailoring-distribution');
