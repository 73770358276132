<template>
    <div class="cohort-detail">
        <template v-if="!loading">
            <template v-if="!isOpenForm">
                <div class="page-header">
                    <h1>Group Details | <span>{{ activeCohort.name }}</span></h1>
                    <div class="actions">
                        <button type="button" class="btn btn-add" @click="createRule(activeCohort)">Add Rule</button>
                        <button type="button" class="btn btn-edit" @click="editCohort(activeCohort)">Edit</button>
                    </div>
                </div>
                <div class="import-section"
                    id="lms-sync-info">
                    <div class="section-header">
                        <h3>Membership Data sync status</h3>
                        <button
                            id="lms-sync-button"
                            type="button" class="btn"
                            data-target="#lms-sync-info"
                            @click="triggerCohortUpdateTask(activeCohort)">
                            Sync Now
                        </button>
                    </div>
                    <p id="lms-sync-completed" v-if="successUpdateTaskDate">
                      <b>Completed:</b> <i>{{ successUpdateFormattedTaskDate }}</i>
                    </p>
                    <p id="lms-sync-inprogress" v-if="startedUpdateTaskDate">
                      <b>In-progress:</b> <i>started at {{ startedUpdateFormattedTaskDate }}</i>
                    </p>
                </div>
                <div class="card card-cohort-detail">
                    <div class="group-details">
                        <div class="membership-details details-section has-light-border border-bottom">
                            <h3>Currently:</h3>
                            <div class="membership">
                                <div class="percent-display">
                                    {{ membershipPercent }}%
                                </div>
                                <div class="count-display">
                                    {{ activeCohort.membership_count }} / {{ enrollmentCount }}<br/>students
                                </div>
                            </div>
                        </div>
                        <div class="mts-details details-section">
                            <h3>Membership Characteristic</h3>
                            <p v-if="activeCohort.characteristic">{{ activeCohort.characteristic }}</p>
                            <p v-else><i>No MTS characteristic assigned</i></p>
                        </div>
                    </div>
                    <div class="group-stats has-light-border border-left">
                        <div class="section-header">
                            <div class="directions">
                                <h3 class="">Group Distribution</h3>
                                <p class="help-text">
                                    Select a characteristic to see the student distribution within this group.
                                    <i>
                                        If a characteristic isn’t listed, make sure it has values or a range in the Michigan Tailoring System (MTS).
                                    </i>
                                </p>
                            </div>
                            <div class="console console-form" v-if="tailoringDistributionLoaded">
                                <div class="survey_question">
                                    <label class="survey_prompt">Characteristic</label>
                                    <div class="survey_responses">
                                        <select v-model="selectedCharacteristic">
                                            <option selected value="">----------</option>
                                            <option v-for="characteristic in Object.keys(tailoringDistribution)" :value="characteristic">
                                                {{ characteristic }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="tailoringDistributionLoaded">
                            <insight-characteristic v-if="selectedCharacteristic"
                                :characteristic="selectedCharacteristic"
                                :distribution="tailoringDistribution[selectedCharacteristic]"
                                :cohort="activeCohort"
                            />
                        </template>
                        <div v-else class="loading-container">
                            <div class="loading-indicator"></div>
                            <span class="loading-text">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="accordion active long-title criteria-breakdown">
                    <div class="accordion-header">
                        <div class="title">Criteria Breakdown</div>
                        <div class="header-container">
                            <div class="table-header">Current Students</div>
                            <div class="toggle"></div>
                        </div>
                    </div>
                    <div class="accordion-content">
                        <div class="table-row" v-for="rule in validRules">
                            <div class="row-title mts-char has-tooltip">
                                <span class="tooltip-container" tabindex="0">
                                    <span class="tooltip-text">
                                        <b>{{ rule.characteristic }} Question Prompt: </b>
                                        <i>“{{ getCharacteristicQuestion(rule.characteristic ) }}”</i>
                                    </span>
                                </span>
                                {{ rule.characteristic }} {{ rule.operation }}
                                <template v-if="rule.operation == 'includes'">{{ getCharacteristicListValueText(rule.characteristic, rule.values_for_include) }}</template>
                                <template v-else>{{ getCharacteristicValueText(rule.characteristic, rule.value) }}</template>
                            </div>
                            <div class="info-block">
                                <div class="row-item">{{ rule.qualifying_subjects_count }} / {{ enrollmentCount }}</div>
                                <div class="buttons">
                                    <button
                                        type="button"
                                        class="edit"
                                        @click="editRule(rule)"
                                        aria-label="Edit Rule">
                                        <span class="tooltip-text">Edit Rule</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="table-row invalid-indicator" v-if="invalidRules.length">
                            <div class="row-title has-tooltip">
                                <span class="tooltip-container" tabindex="0">
                                    <span class="tooltip-text">
                                        <i>
                                            The following rule(s) were creatd with errors.
                                            Correct these errors to allow all criteria
                                            to be included.
                                        </i>
                                    </span>
                                </span>
                                Invalid Criteria
                            </div>
                        </div>
                        <div class="table-row secondary" v-for="rule in invalidRules" :class="{invalid: !rule.is_valid}">
                            <div class="row-title">
                                <div class="indent">
                                    {{ rule.characteristic }} {{ rule.operation }}
                                    <template v-if="rule.operation == 'includes'">{{ getCharacteristicListValueText(rule.characteristic, rule.values_for_include) }}</template>
                                    <template v-else>{{ getCharacteristicValueText(rule.characteristic, rule.value) }}</template>
                                </div>
                            </div>
                            <div class="info-block">
                                <div class="row-item">{{ rule.qualifying_subjects_count }} / {{ enrollmentCount }}</div>
                                <div class="buttons">
                                    <button
                                        type="button"
                                        class="edit"
                                        @click="editRule(rule)"
                                        aria-label="Edit Rule">
                                        <span class="tooltip-text">Edit Rule</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="!activeCohort.rules.length" class="table-row empty">No rules added</div>
                    </div>
                </div>
            </template>
            <div class="section-list">
                <modal
                    :modalClasses="'active form-modal'"
                    v-if="showRuleForm">
                    <logic-rule-form
                        :cohort="activeCohort"
                        :instanceData="ruleInstance"
                        :formErrors="ruleFormErrors"
                        @save-instance="saveRule"
                        @delete-instance="deleteRule"
                        @cancel-form="resetForm"
                    />
                </modal>
            </div>
            <div v-if="showCohortForm" class="console-form in-page-view-form">
                <div class="header-section">
                    <div class="header-plus-btn">
                        <button
                            class="btn btn-circle btn-arrow left-btn"
                            aria-label="Cancel edit"
                            type="button"
                            @click="resetForm">
                            <div class="icon-container icon-arrow">
                                <svg class="link-arrow icon-navy" role="presentation">
                                    <use href="/static/img/arrows.svg#leftArrow"></use>
                                </svg>
                            </div>
                        </button>
                        <h1>Edit Group</h1>
                    </div>
                    <button type="button"
                        class="btn btn-delete"
                        @click="showDeleteModal = true">
                        Delete
                    </button>
                </div>
                <cohort-form
                  :instanceData="activeCohort"
                  :formErrors="{}"
                  @save-instance="saveCohortInstance"
                  @cancel-form="resetForm"
                  @delete-instance="deleteCohortInstance"
                />
                <modal
                  v-if="showDeleteModal"
                  :modalClasses="'active'"
                  @modal-cancel="showDeleteModal = false"
                >
                  <div class="modal-info">
                    <h2>Delete Insight Group</h2>
                    <p>
                      <b>Are you sure you want to delete the Insight Group: “{{ activeCohort.name }}”? This cannot be undone.</b>
                    </p>
                  </div>
                  <template v-slot:wrapper-modal-footer>
                    <div class="button-block">
                      <button type="button"
                          class="btn btn-cancel"
                          @click="showDeleteModal = false">
                          Cancel
                      </button>
                      <button type="button"
                          class="btn btn-delete"
                          @click="deleteCohortInstance(activeCohort)">
                          Yes, delete
                      </button>
                    </div>
                  </template>
                </modal>
            </div>
        </template>
        <div v-else class="loading-container">
            <div class="loading-indicator"></div>
            <span class="loading-text">Loading...</span>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTailoringStore } from './stores/tailoring.js';

import CohortForm from './CohortForm.vue';
import LogicRuleForm from "./LogicRuleForm.vue";
import LogicRuleRow from "./LogicRuleRow.vue";
import InsightCharacteristic from "./InsightCharacteristic.vue";
import Modal from '../../components/generic/Modal.vue';

import { cohortManagerUrl } from "./urls.js";
import { loadingMixin } from "../../mixins/loading.js";
import { FocusTrap } from 'focus-trap-vue';

export default {
  name: 'CohortDetail',
  mixins: [
    loadingMixin,
  ],
  components: {
    CohortForm,
    LogicRuleForm,
    LogicRuleRow,
    InsightCharacteristic,
    Modal,
  },
  data () {
    return {
      showDeleteModal: false,
      showCohortForm: false,
      showRuleForm: false,
      ruleInstance: {},
      ruleFormErrors: {},
      updateTimeout: null,
      selectedCharacteristic: "",
    };
  },
  computed: {
    ...mapState(useTailoringStore, [
      'activeCohort',
      'enrollmentCount',
      'tailoringDistribution',
      'successUpdateTaskDate',
      'startedUpdateTaskDate',
      'characteristics',
    ]),
    isOpenForm(){
      return (this.showCohortForm)
    },
    successUpdateFormattedTaskDate() {
      return this.$luxonDateTime.fromISO(this.successUpdateTaskDate).toFormat('t - M.d.yy');
    },
    startedUpdateFormattedTaskDate() {
      return this.$luxonDateTime.fromISO(this.startedUpdateTaskDate).toFormat('t - M.d.yy');
    },
    tailoringDistributionLoaded() {
      return this.tailoringDistribution != null;
    },
    membershipPercent () {
      return this.enrollmentCount ? Math.round((this.activeCohort.membership_count / this.enrollmentCount) * 100) : 0;
    },
    validRules () {
      return this.activeCohort.rules.filter(rule => rule.is_valid);
    },
    invalidRules () {
      return this.activeCohort.rules.filter(rule => !rule.is_valid);
    },
  },
  methods: {
    ...mapActions(useTailoringStore, [
      'getCohortGroupList',
      'getCohortList',
      'loadCohortData',
      'getCohortData',
      'saveCohort',
      'deleteCohort',
      'saveCohortRule',
      'deleteCohortRule',
      'getMTSDictionaryData',
      'getCohortMembershipTask',
      'getEnrollmentCount',
      'triggerCohortMembershipTask',
      'getTailoringDistribution',
      'setTailoringDistribution',
      'setUpdatedCohortTask',
    ]),
    editCohort(instanceData) {
      this.instance = instanceData;
      this.showCohortForm = true;
    },
    saveCohortInstance(instanceData) {
      this.saveCohort(instanceData).then(() => {
        this.loadCohortData();
        this.resetForm();
      });
    },
    deleteCohortInstance(instanceData) {
      this.deleteCohort(instanceData).then(() => {
        window.location.href = cohortManagerUrl.stringify({
          coachId: this.activeCohort.coach_id,
        });
      });
      this.loading = true;
    },
    saveRule(instance) {
      this.saveCohortRule(instance).then((response) => {
        if (response.errors) {
          this.ruleFormErrors = response.errors;
        } else {
          this.resetForm();
          this.loadCohortData();
          this.fetchTailoringDistribution();
        }
      });
    },
    deleteRule(instance) {
      this.deleteCohortRule(instance).then(() => {
        this.resetForm();
        this.loadCohortData();
        this.fetchTailoringDistribution();
      });
    },
    createRule(cohort) {
      this.showRuleForm = true;
      this.ruleInstance = {};
    },
    editRule(rule) {
      this.showRuleForm = true;
      this.ruleInstance = rule;
    },
    resetForm() {
      this.showRuleForm = false;
      this.showCohortForm = false;
      this.ruleInstance = {};
      this.ruleFormErrors = {};
      this.refreshCohortUpdateTaskData();
    },
    triggerCohortUpdateTask() {
      if(!this.startedUpdateTaskDate) {
        this.triggerCohortMembershipTask(this.activeCohort).then(() => {
            this.refreshCohortUpdateTaskData();
            this.loadCohortData();
          }
        );
      }
    },
    refreshCohortUpdateTaskData() {
      this.getCohortMembershipTask(this.activeCohort).then(() => {
        if(!this.updateTimeout) {
          this.updateTimeout = setTimeout(() => {
            this.updateTimeout = null;
            this.refreshCohortUpdateTaskData();
          }, 15000);
        }
      });
    },
    fetchTailoringDistribution() {
      this.setTailoringDistribution(null);
      this.getTailoringDistribution(this.activeCohort);
    },
    getCharacteristicDefinition (charName) {
      return this.characteristics.find(characteristicDefinition => characteristicDefinition.name === charName);
    },
    getCharacteristicQuestion (charName) {
      let charDef = this.getCharacteristicDefinition(charName);
      return charDef ? charDef.question : ""
    },
    getCharacteristicListValueText (charName, valueList) {
      console.log("Value List", valueList);
      return valueList.map(valueItem => this.getCharacteristicValueText(charName, valueItem));
    },
    getCharacteristicValueText (charName, value) {
      console.log(charName, value);
      let charDef = this.getCharacteristicDefinition(charName);
      let valueText = value;
      if (charDef && charDef.restrictionset && charDef.restrictionset.values) {
        valueText = charDef.restrictionset.values.find(valItem => valItem.value == value);
      }
      return valueText ? valueText.label : value;
    },
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([
        this.getCohortGroupList(),
        this.loadCohortData(),
        this.getMTSDictionaryData(),
        this.getEnrollmentCount(),
      ]).then(() => {
        this.stopLoading();
        this.refreshCohortUpdateTaskData();
        this.fetchTailoringDistribution();
      });
    },
  },
  created () {
    this.loadInterfaceData();
  },
}

</script>
