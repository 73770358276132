<template>
    <div class="group-collections-section">
        <div class="section-header has-border">
            <h3>Insight Group Collections</h3>
            <div class="actions">
                <button class="btn btn-add" @click="$emit('showGroupForm', true)">Add Collection</button>
            </div>
        </div>
        <div class="empty" v-if="!cohortGroups.length">
            No collections exist
        </div>
        <template v-else-if="activeGroup">
            <div class="subsection-header">
                <div class="header-plus-btn">
                    <button class="btn btn-circle btn-arrow icon-container container-royal-blue icon-arrow" type="button" @click="activeGroup = null">
                        <svg class="link-arrow" role="presentation">
                            <use href="/static/img/arrows.svg#leftArrow"></use>
                        </svg>
                    </button>
                    <h3>{{ activeGroup.name }}</h3>
                </div>
                <div class="actions">
                    <button type="button" class="btn btn-edit" @click="$emit('edit-instance', activeGroup)">Edit</button>
                    <label class="view-toggle" v-bind:class = "{ checked : !cohortViewMode }">
                        <input type="checkbox" v-model="cohortViewMode" />
                        <span class="icon-container">
                            <span class="view-icon card-view">
                                <svg class="card-view-icon" role="presentation">
                                    <use href="/static/img/IconSprite.svg#cardView"></use>
                                </svg>
                            </span>
                            <span class="view-icon table-view">
                                <svg class="table-view-icon" role="presentation">
                                    <use href="/static/img/IconSprite.svg#tableView"></use>
                                </svg>
                            </span>
                        </span>
                    </label>
                </div>
            </div>
            <div v-if="cohortViewMode" class="groups card-view">
                <cohort-card-details-card-view
                    v-for="cohort in activeCohorts"
                    :key="cohort.id"
                    :cohort="cohort"
                />
                <div class="empty" v-if="!activeCohorts.length">
                    No groups in collection
                </div>
            </div>
            <div v-else class="groups table-view">
                <div class="table-view-header">
                    <div class="name">Name</div>
                    <div class="students">
                        <div class="students-header">Students</div>
                        <div class="membership">
                            <div>Current</div>
                            <div>Total</div>
                            <div>Percent</div>
                        </div>
                    </div>
                    <div class="action">Action</div>
                </div>
                <cohort-card-details-table-view
                    v-for="cohort in activeCohorts"
                    :key="cohort.id"
                    :cohort="cohort"
                />
                <div class="empty" v-if="!activeCohorts.length">
                    No groups in collection
                </div>
            </div>
        </template>
        <div v-else class="group-collections card-view">
            <div v-for="cohortGroup in cohortGroups" :key="cohortGroup.id" class="collection-container">
                <div class="collection">
                    <div class="icon-container">
                        <svg class="collection-icon" role="presentation">
                            <use href="/static/img/IconSprite.svg#collections"></use>
                        </svg>
                    </div>
                    <div class="collection-details">
                        <h4>{{ cohortGroup.name }}</h4>
                        <div class="group-display">{{ cohortCountText(cohortGroup) }}</div>
                    </div>
                    <div class="actions">
                        <button type="button" class="btn" @click="activeGroup = cohortGroup">View</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useTailoringStore } from './stores/tailoring.js'

import CohortCardDetailsCardView from "./CohortCardDetailsCardView.vue";
import CohortCardDetailsTableView from "./CohortCardDetailsTableView.vue";

export default {
  name: 'CohortGroupList',
  data () {
    return {
      activeGroup: null,
      cohortViewMode: true,
    };
  },
  components: {
    CohortCardDetailsCardView,
    CohortCardDetailsTableView,
  },
  computed: {
    ...mapState(useTailoringStore, [
      'cohortGroups',
      'cohorts'
    ]),
    cohortGroupsExist() {
        return !!(this.cohortGroups || []).length;
    },
    activeCohorts() {
      return this.activeGroup ? this.cohorts.filter(
        cohort => cohort.cohort_group === this.activeGroup.id
      ) : [];
    },
  },
  methods: {
    cohortCount(group) {
      return this.cohorts.filter(
        cohort => cohort.cohort_group === group.id
      ).length;
    },
    cohortCountText(group) {
      let count = this.cohortCount(group);
      return `${count} ${count === 1 ? 'Group' : 'Groups'}`;
    }
  },
}

</script>
