<template>
  <div class="todo-list">
    <div class="card-header">
      <h1>To-Dos</h1>
      <div class="todo-list-nav card-nav" id="listNavigation">
        <template v-if="showPrevButton">
          <button
            class="link nav-item nav-prev"
            type="button"
            aria-label="Previous List"
            @click="loadPrev()"
          >
            <div class="icon-container">
              <svg class="icon-arrow icon-navy" role="presentation">
                <use :xlink:href="leftArrow"></use>
              </svg>
            </div>
            Previous List
          </button>
        </template>
        <div class="divider" v-if="showPrevButton && showNextButton"></div>
        <template v-if="showNextButton">
          <button
            class="link nav-item nav-next"
            type="button"
            data-slide="next"
            aria-label="Next List"
            @click="loadNext()"
          >
            Next List
            <div class="icon-container">
              <svg class="icon-arrow icon-navy" role="presentation">
                <use :xlink:href="rightArrow"></use>
              </svg>
            </div>
          </button>
        </template>
      </div>
    </div>
    <div v-if="!(todoListData)" class="card card-empty">
        <div class="icon-container">
            <svg class="empty-icon" role="presentation">
                <use xlink:href="/static/img/IconSprite.svg#toDo"></use>
            </svg>
        </div>
        <p><i>There are currently no To-Do items.</i></p>
    </div>
    <div v-else class="card card-to-do card-has-overflow">
      <div class="nested-card-header header-plus-btn">
        <div v-if="todoListData.is_queued" class="status-label scheduled">
          Scheduled
        </div>
        <div v-if="todoListData.is_under_review" class="status-label draft">
          Drafted
        </div>
        <h3 v-if="todoListData.title" id="listTitle">
          {{ todoListData.title }}
        </h3>
        <h3 v-else class="empty-title">No list title</h3>
        <button
          class="btn btn-seafoam"
          @click="updateShowCreateModal()"
          type="button"
          aria-label="Add Your Own To-Do Item"
        >
          Add Your Own
        </button>
      </div>
      <div class="todo-items">
        <to-do-item
          v-for="todoitem in todoListData.items"
          :key="todoitem.id"
          :todoitem="todoitem"
          @detail-editor="$emit('detail-editor')"
        />
      </div>
      <Teleport to="#slotWrapper">
        <to-do-list-modal />
      </Teleport>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTodoStore } from '../../stores/todo.js';

import ToDoModal from "./ToDoModal.vue";
import ToDoItem from "./ToDoItem.vue";
import { leftArrow, rightArrow } from "../../images.js";

export default {
  name: "ToDoList",
  data() {
    return {
      loading: true,
      leftArrow: leftArrow,
      rightArrow: rightArrow,
    };
  },
  components: {
    "to-do-list-modal": ToDoModal,
    "to-do-item": ToDoItem,
  },
  methods: {
    ...mapActions(useTodoStore, [
      'loadTodoListData',
      'loadTodoItemTagsData',
      'displaySelectedTodoList',
      'updateCurrentPage',
      'updateTodoListUrl',
      'updateShowCreateModal',
    ]),
    loadNext() {
      this.updateCurrentPage(this.currentPage + 1);
      this.updateTodoListUrl(this.currentPage);
      this.loadTodoListData();
    },
    loadPrev() {
      this.updateCurrentPage(this.currentPage - 1);
      this.updateTodoListUrl(this.currentPage);
      this.loadTodoListData();
    },
  },
  computed: {
    ...mapState(useTodoStore, [
      'todoListData',
      'currentPage',
      'showNextButton',
      'showPrevButton',
    ]),
  },
  async created() {
    await this.loadTodoListData();
    this.loadTodoItemTagsData();
    this.loading = false;
  },
};
</script>
