<template>
  <div class="image-preview" id="selectImage">
    <div class="display-image">
      <img :src="selectedImageSrc" alt="Image preview" v-if="selectedImageSrc" />
    </div>
    <button
      class="btn"
      @click="toggleImageSelectOpenState()"
      type="button"
      aria-label="Update presentation image"
    >
      Update Image
    </button>
    <div class="hidden-field">
      <input
        type="hidden"
        name="picture_url"
        maxlength="200"
        :id="imageData.id"
        v-if="selectImageURL && ExistedFlag"
        v-model="selectImageURL"
      />
      <input
        type="hidden"
        name="picture_url"
        maxlength="200"
        :id="imageData.id"
        v-else-if="imageData && !selectImageURL && ExistedFlag"
        value="_topic-img_general-default.png"
      />
      <input type="hidden" name="ExistedFlag" :value="ExistedFlag ? 'true' : 'false'">
      <input type="file" name = "picture_img" ref="fileInput" class="hidden" accept="image/*" @change="handleFileUpload">
    </div>
  </div>
  <Teleport to="body">
     <div v-if="isOpen" class="modal-contain active" @modal-cancel="toggleImageSelectOpenState()">
        <iconlist></iconlist>
    </div>

  </Teleport>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useIconPickerStore } from '../../stores/icon-picker.js';
import { useImageSelectStore } from '../../stores/image-select.js';

import IconList from './IconList.vue';
import Modal from '../generic/Modal.vue';

export default {
  name: "IconSelectItem",
  data() {
    return {
      modalOpen: false
    };
  },
  components: {
    modal: Modal,
    iconlist: IconList,
  },
  methods: {
    ...mapActions(useIconPickerStore, [
      'loadImageFieldData',
      'setExistedFlag',
      'setTriggerUpload',
      'updateSelectedFile',
    ]),
    ...mapActions(useImageSelectStore, [
      'loadImageFieldData',
      'toggleImageSelectOpenState',
      'updateImageFieldData',
    ]),
    uploadNew() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      if (event.target.files.length === 0) {
        return;
      }

      this.setExistedFlag(false);
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        this.updateSelectedFile(reader.result);
      };
      reader.readAsDataURL(file);
      this.toggleImageSelectOpenState();
    },
  },
  computed: {
    ...mapState(useIconPickerStore, [
      'ExistedFlag',
      'selectedFile',
      'triggerUpload',
    ]),
    ...mapState(useImageSelectStore, [
      'imageField',
      'isOpen',
      'selectImageURL',
      'selectImagePath',
    ]),
    imageData() {
      let imageData = this.imageField;
      return imageData?.length ? imageData[0] : null;
    },
    selectedImageSrc () {
        if (this.imageData && !this.selectImageURL && this.ExistedFlag) {
            return this.imageData.default_image_path;
        } else if (this.selectImageURL && this.ExistedFlag) {
            return this.selectImagePath;
        } else if (!this.ExistedFlag) {
            return this.selectedFile;
        }
    }
  },
  watch: {
    triggerUpload (newVal) {
      if (newVal) {
        this.uploadNew();
        this.setTriggerUpload(false);
      }
    }
  },
  mounted () {
    this.loadImageFieldData();
  }
};
</script>
