<template>
    <button class="btn btn-star"
        type="button" @click.stop.prevent="toggleBulletinStarStatus()">
        <svg class="star-icon">
            <use xlink:href="/static/img/star.svg#starFilled" v-if="starred"></use>
            <use xlink:href="/static/img/star.svg#star" v-else></use>
        </svg>
    </button>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useBulletinStore } from './bulletin.js';

export default {
    name: 'BulletinStarButton',
    props: {
      bulletinId: {
        required: true
      },
    },
    data () {
      return {};
    },
    computed: {
      ...mapState(useBulletinStore, [
        'allBulletins',
      ]),
      bulletin () {
        return this.allBulletins.find(bulletin => bulletin.id === Number(this.bulletinId));
      },
      starred () {
        return this.bulletin?.starred;
      }
    },
    methods: {
      ...mapActions(useBulletinStore, [
        'loadBulletinList',
        'starBulletinToggle',
      ]),
      toggleBulletinStarStatus(instanceData) {
        this.starBulletinToggle(this.bulletin).then((response) => {
          this.loadBulletinList();
        });
      },
    },
}
</script>
