<template>
    <div>
        <div class="content modal-info">
            <h2>Select Image</h2>
        </div>
        <div class="survey_question image-directory">
            <label class="survey_prompt">
                <template v-if="isAppleDevice">Double tap the image</template>
                <template v-else>Please select the image</template> that is most applicable to this bulletin
            </label>
            <div class="survey_responses">
                <ul class="image-list">
                    <li
                        v-for="image in imageListData"
                        :key="image.file_name"
                        @click="selectImage(image)">
                        <label>
                            <input class="sr-only" type="radio" :id="image.file_name" name="message-img">
                            <img :src="image.file_path" />
                        </label>
                    </li>
                </ul>
            </div>
        </div>
        <div class="button-block">
            <button class="btn btn-cancel" type="button" @click="toggleImageSelectOpenState()">Cancel</button>
            <button class="btn" @click="saveSelect()">Select</button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useImageSelectStore } from '../../stores/image-select.js';

export default({
    name: 'ImageList',
    data () {
      return {
        selectedImage: ''
      };
    },
    methods: {
      ...mapActions(useImageSelectStore, [
        'toggleImageSelectOpenState',
        'displaySelectedImage',
        'loadImageData',
      ]),
      selectImage (image) {
        this.selectedImage = image;
      },
      saveSelect () {
        this.displaySelectedImage(this.selectedImage)
        this.toggleImageSelectOpenState()
      }
    },
    computed: {
      ...mapState(useImageSelectStore, [
        'imageListData',
        'ExistedFlag',
        'selectedFile',
      ]),
      isAppleDevice(){
        let userAgent = window.navigator.userAgent;
        return userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
      },
    },
    mounted () {
      this.loadImageData();
    }
});
</script>
