<template>
    <div class="divided-block small-divided-block has-right-border">
        <div class="count-container">
            <div class="count">
                <div class="icon-container container-white">
                    <svg class="card-icon">
                        <use xlink:href="/static/img/IconSprite.svg#activity"></use>
                    </svg>
                </div>
                <span>{{coachUserActivityHistory.total_completed_assignments}}</span>
            </div>
            <p>Of <b><i>{{coachUserActivityHistory.total_assignments_count}} Items</i></b> Complete</p>
        </div>
        <activity-bar-graph
            graph_title=""
            bar_color="graph-royal-blue"
            :read_percentage="`${coachUserActivityHistory.assignment_completed_percentage}%`"
            complete_type="read"
            content_label="bulletin"
        >
            <p><b>{{coachUserActivityHistory.total_completed_assignments}}</b> Complete | <b>{{coachUserActivityHistory.incomplete_assignment_count}}</b> Incomplete</p>
        </activity-bar-graph>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useStudentActivityStore } from '../../stores/activity-history.js';

import ActivityBarGraph from './ActivityBarGraph.vue';

export default {
    name: "CoachActivityOverview",
    components: {
        ActivityBarGraph,
    },
    data() {
        return {
            selectedCoach: null,
        }
    },
    computed: {
        ...mapState(useStudentActivityStore, [
            'coachUserActivityHistory',
        ]),
    },
}
</script>
