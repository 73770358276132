import axios from 'axios';

import { defineStore } from 'pinia';

import {
  coachBulletinBoardUrl,
  institutionBulletinBoardUrl,
  coachBulletinPageUrl,
  coachInteractiveBulletinPageUrl,
  institutionBulletinPageUrl,
  institutionInteractiveBulletinPageUrl,
  coachBulletinListUrl,
  institutionBulletinListUrl,
  coachStarBulletinUrl,
  institutionStarBulletinUrl
} from '../../urls.js';

import { genericMixin } from '../../stores/generic.js';

const coachInfo = coachBulletinPageUrl.match(location.pathname) || coachInteractiveBulletinPageUrl.match(location.pathname);
const coachId = coachInfo ? Number(coachInfo.coachId) : null;

const institutionInfo = (
  institutionBulletinPageUrl.match(location.pathname) || 
  institutionInteractiveBulletinPageUrl.match(location.pathname) || 
  institutionBulletinBoardUrl.match(location.pathname)
);
const institutionId = institutionInfo ? Number(institutionInfo.institutionId) : null;

const activeBulletinId = coachInfo ? coachInfo.bulletinId : institutionInfo ? institutionInfo.bulletinId : null;

const initialState = {
  coachId,
  institutionId,
  activeBulletinId,
  bulletinList: null,
}

export const useBulletinStore = defineStore('bulletin', {
  namespaced: true,
  state: () => initialState,
  getters: {
    ...genericMixin.getters,
    allBulletins: state => {
      return state.bulletinList || [];
    },
    starredBulletins: state => {
      return state.bulletinList ? state.bulletinList.filter(bulletin => bulletin.starred) : [];
    },
    recommendedBulletins: state => {
      return state.bulletinList ? state.bulletinList.filter(bulletin => bulletin.recommended) : [];
    },
    activeBulletin: state => {
      return state.allBulletins.find(bulletin => bulletin.id === Number(state.activeBulletinId));
    },
    loadBulletinConfig: state => {
      return state.coachId ? {
        urlParams: { coachId: state.coachId }, 
        urls: { list: coachBulletinListUrl }
      } : {
        urlParams: { institutionId: state.institutionId }, 
        urls: { list: institutionBulletinListUrl }
      };
    },
  },
  actions: {
    ...genericMixin.actions,
    setBulletinList ({ data }) {
      this.bulletinList = data;
    },
    // Cohort Group Actions //
    loadBulletinList () {
      const bulletinConfig = this.loadBulletinConfig;
      return this.loadObjectList({
        mutation: this.setBulletinList,
        ...bulletinConfig
      });
    },
    starBulletinToggle(instance) {
      const starBulletinUrl = (this.coachId ? coachStarBulletinUrl : institutionStarBulletinUrl).stringify({
        coachId: this.coachId,
        institutionId: this.institutionId,
        bulletinId: instance.id,
      });
      return axios.post(starBulletinUrl, instance).then(
        (response) => response.data
      ).catch((error) => {
        return { errors: error.response.data };
      });
    },
  }
});