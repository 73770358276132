<template>
    <div class="divided-block graph-block">
        <div class="nested-card-header header-plus-key">
            <h3>Content Breakdown</h3>
            <div class="graph-key horizontal">
                <div  v-for="key in keys" class="key-item key-royal-blue" :class="key.classes">
                    <div class="key-color"></div>
                    <div class="key-label">{{ key.label }}</div>
                </div>
            </div>
        </div>
        <div class="graph-section">
            <activity-bar-graph v-for="graph in graphs" 
                :graph_title="graph.graph_title"
                :bar_color="graph.bar_color"
                :read_percentage="graph.read_percentage"
                :complete_type="graph.complete_type"
                :content_label="graph.content_label"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useStudentActivityStore } from '../../stores/activity-history.js';

import ActivityBarGraph from './ActivityBarGraph.vue';

export default {
    name: "InstitutionCoachActivityHistory",
    components: {
        ActivityBarGraph,
    },
    data() {
        return {
            keys: [
                { label: 'Complete', classes: {'key-solid': true, 'key-read': true} },
                { label: 'Incomplete', classes: {'key-diag': true, 'key-unread': true}},
                { label: 'Total', classes: {'key-total': true} }
            ],
        }
    },
    computed: {
        ...mapState(useStudentActivityStore, [
            'bulletinActivityHistory',
            'playbookActivityHistory',
            'surveyActivityHistory',
            'toDoActivityHistory',
        ]),
        graphs() {
            let graphs = [
                {
                    graph_title: "Bulletins",
                    bar_color: "graph-yellow",
                    read_percentage: `${this.bulletinActivityHistory.read_messages_percentage}%`,
                    complete_type: "read",
                    content_label: "bulletin",
                },
                {
                    graph_title: "Surveys",
                    bar_color: "graph-salmon",
                    read_percentage: `${this.surveyActivityHistory.survey_completed_percentage}%`,
                    complete_type: "completed",
                    content_label: "survey",
                },
                {
                    graph_title: "Playbooks",
                    bar_color: "graph-seafoam",
                    read_percentage: `${this.playbookActivityHistory.playbook_completed_percentage}%`,
                    complete_type: "completed",
                    content_label: "playbook",
                },
            ]
            if (this.toDoActivityHistory) {
                graphs.push({
                    graph_title: "To-Dos",
                    bar_color: "graph-bright-blue",
                    read_percentage: this.toDoActivityHistory.completed_todo_item_percentage,
                    complete_type: "completed",
                    content_label: "to-do",
                })
            }
            return graphs
        }
    },
}
</script>