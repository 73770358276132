<template>
    <div class="group-container">
        <div class="group">
            <h4>{{ cohort.name }}</h4>
            <div class="group-details">
                <div class="membership">
                    <div class="percent-display">
                        {{ membershipPercent }}%
                    </div>
                    <div class="count-display">
                        {{ cohort.membership_count }} / {{ enrollmentCount }}<br/>students
                    </div>
                </div>
                <a class="btn btn-light-grey" :href="getCohortDetailUrl(cohort)">Details</a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTailoringStore } from './stores/tailoring.js';

import LogicRuleForm from "./LogicRuleForm.vue";
import LogicRuleRow from "./LogicRuleRow.vue";
import { cohortDetailUrl } from "./urls.js";

export default {
  name: 'CohortCardDetailsCardView',
  components: {
    LogicRuleForm,
    LogicRuleRow,
  },
  props: {
    cohort: {
      type: Object,
      default: () => {},
    }
  },
  data () {
    return {
      activeCohortId: null,
      showRuleForm: false,
      ruleInstance: {},
    };
  },
  computed: {
    ...mapState(useTailoringStore, [
      'coachId',
      'enrollmentCount',
      'ungroupedCohorts',
    ]),
    membershipPercent () {
      return this.enrollmentCount ? Math.round((this.cohort.membership_count / this.enrollmentCount) * 100) : 0;
    }
  },
  methods: {
    ...mapActions(useTailoringStore, [
      'saveCohortRule',
      'deleteCohortRule',
      'getCohortList',
    ]),
    saveRule(instance) {
      this.saveCohortRule(instance).then(() => {
        this.resetForm();
        this.getCohortList();
      });
    },
    deleteRule(instance) {
      this.deleteCohortRule(instance).then(() => {
        this.resetForm();
        this.getCohortList();
      });
    },
    createRule(cohort) {
      this.activeCohortId = cohort.id;
      this.showRuleForm = true;
      this.ruleInstance = {};
    },
    editRule(rule) {
      this.activeCohortId = rule.cohort_id;
      this.showRuleForm = true;
      this.ruleInstance = rule;
    },
    resetForm() {
      this.showRuleForm = false;
      this.activeCohortId = null;
      this.ruleInstance = {};
    },
    getCohortDetailUrl(cohort) {
      return cohortDetailUrl.stringify({
        coachId: this.coachId,
        cohortId: cohort.id
      });
    }
  },
}

</script>
