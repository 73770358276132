import axios from "axios";
import { defineStore } from 'pinia';

import {
  adminInsitutionBaseURL,
  adminCoachBaseUrl,
  messageWildcardUrl,
  institutionImageListData,
  institutionImageSelectionUrl,
  institutionImageUpdateSelectionUrl,
  coachImageListData,
  coachImageSelectionUrl,
  coachImageUpdateSelectionUrl,
} from '../urls.js';

const coachInfo = adminCoachBaseUrl.match(location.pathname);
const institutionInfo = adminInsitutionBaseURL.match(location.pathname);
const messageInfo = messageWildcardUrl.match(location.pathname);

let imageSelectUrl;
let imageListUrl;

if (coachInfo) {
  imageListUrl = coachImageListData.stringify(coachInfo);
  imageSelectUrl = (messageInfo
    ? coachImageUpdateSelectionUrl.stringify({...coachInfo, ...messageInfo})
    : coachImageSelectionUrl.stringify(coachInfo));
} else if (institutionInfo) {
  imageListUrl = institutionImageListData.stringify(institutionInfo);
  imageSelectUrl = (messageInfo
    ? institutionImageUpdateSelectionUrl.stringify({...institutionInfo, ...messageInfo})
    : institutionImageSelectionUrl.stringify(institutionInfo)
  );
}

const initialState = {
  imageListData: {},
  imageField: {},
  isOpen: false,
  selectImageURL: '',
  selectImagePath: '',
  imageListUrl: imageListUrl,
  imageSelectItemUrl: imageSelectUrl,
}

export const useImageSelectStore = defineStore('image-select', {
  state: () => initialState,
  actions: {
    updateImageData (payload) {
      this.imageListData = payload;
    },
    updateImageFieldData (payload) {
      this.imageField = payload;
    },
    toggleImageSelectOpenState (payload) {
      this.isOpen = this.isOpen === false;
    },
    displaySelectedImage (image) {
      this.selectImageURL = image.file;
      this.selectImagePath = image.file_path;
    },
    async loadImageData () {
      return axios.get(this.imageListUrl).then((response) => {
        this.updateImageData(response.data);
      });
    },
    async loadImageFieldData () {
      return axios.get(this.imageSelectItemUrl).then((response) => {
        this.updateImageFieldData(response.data);
      });
    },
  },
});