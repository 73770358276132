<template>
    <div class="console-form">
        <div class="header-section">
            <h1 v-if="editing && !showDeleteConfirmation">Edit Collection Group</h1>
            <h1 v-else-if="showDeleteConfirmation">Delete Collection Group</h1>
            <h1 v-else>Add Collection Group</h1>
            <button v-if="editing && !showDeleteConfirmation" type="button"
                class="btn btn-delete"
                @click="showDeleteConfirmation = true"
                >
                Delete
            </button>
        </div>
        <div v-if="showDeleteConfirmation">
            <div class="content">
                <p>
                    Are you sure you want to delete the Insight Collection Group: “{{ instanceData.name }}”? This action cannot be undone.
                </p>
            </div>
            <div class="button-block">
                <button type="button" class="btn btn-cancel"
                    @click="showDeleteConfirmation = false">
                    Cancel
                </button>
                <button type="button" class="btn btn-delete"
                    @click="deleteInstance()">
                    Yes, delete
                </button>
            </div>
        </div>
        <FormKit
            v-show="!showDeleteConfirmation"
            v-model="formData"
            :input-errors="formErrors"
            :actions="false"
            type="form"
        >
            <ul class="errorlist" v-if="formErrors.length">
                <li v-for="error in formErrors.base">{{ error }}</li>
            </ul>
            <FormKit
                name="name"
                label="Collection Name"
                class="survey_question"
                label-class="survey_prompt"
                placeholder="Collection Name"
                type="text"
                validation="required"
            >
                <template #label="context">
                    <div :class="context.classes.label" :for="context.id">
                        Collection Name <span class="required-item">*</span>
                    </div> 
                </template>
            </FormKit>
            <div class="button-block">
                <button type="button" class="btn btn-cancel"
                    @click="cancelForm">
                    Cancel
                </button>
                <button type="button" class="btn"
                    @click="saveInstance">
                    Save
                </button>
            </div>
        </FormKit>
    </div>
</template>

<script>
import { formDataMixin } from '../../mixins/formData.js';

export default {
  name: 'CohortGroupForm',
  data() {
    return {
        showDeleteConfirmation: false,
    }
  },
  mixins: [
    formDataMixin
  ],
}
</script>
