import axios from 'axios';
import { defineStore } from 'pinia';

import {
  adminCoachBaseUrl,
  cohortDetailUrl,
  cohortGroupUrls,
  cohortUrls,
  cohortRuleUrls,
  mtsdictionaryDataUrl,
  coachEnrollmentDetailsUrl,
  updateCohortMembershipUrl,
  cohortTailoringDistributionUrl,
} from '../urls.js';

import { genericMixin } from '../../../stores/generic.js';

const coachInfo = adminCoachBaseUrl.match(location.pathname);
const coachId = coachInfo ? Number(coachInfo.coachId) : null;

const cohortInfo = cohortDetailUrl.match(location.pathname);
const activeCohortId = cohortInfo ? Number(cohortInfo.cohortId) : null;

const initialState = {
  coachId,
  cohortGroups: [],
  cohorts: [],
  activeCohortId,
  activeCohort: null,
  activeCohortRules: [],
  activeCohortUsers: [],
  mtsdictionaryData: null,
  enrollmentCount: null,
  updatedCohortTask: null,
  tailoringDistribution: null,
}

export const useTailoringStore = defineStore('tailoring', {
  namespaced: true,
  state: () => initialState,
  getters: {
    ...genericMixin.getters,
    characteristics: state => {
      return state.mtsdictionaryData ? state.mtsdictionaryData.characteristics : [];
    },
    sources: state => {
      return state.mtsdictionaryData ? state.mtsdictionaryData.sources : [];
    },
    sourcedCharacteristics: state => {
      return state.mtsdictionaryData ? state.mtsdictionaryData.sources.map(source => {
        return {
          source: source,
          characteristics: state.mtsdictionaryData.characteristics.filter(
            characteristic => characteristic.sources.includes(source)
          )
        };
      }) : {};
    },
    ungroupedCohorts: state => {
      return state.cohorts ? state.cohorts.filter(
        cohort => !cohort.cohort_group
      ) : [];
    },
    successUpdateTask: state => {
      return state.updatedCohortTask ? state.updatedCohortTask.success : null;
    },
    successUpdateTaskDate: state => {
      return state.successUpdateTask ? state.successUpdateTask.date_done : null;
    },
    startedUpdateTask: state => {
      return state.updatedCohortTask ? state.updatedCohortTask.started : null;
    },
    startedUpdateTaskDate: state => {
      return state.startedUpdateTask ? state.startedUpdateTask.date_done : null;
    },
  },
  actions: {
    ...genericMixin.actions,
    setCohortGroupList ({ data }) {
      this.cohortGroups = data;
    },
    setCohortList ({ data }) {
      this.cohorts = data;
    },
    setActiveCohort ({ data }) {
      this.activeCohort = data;
    },
    setMTSDictionaryData ({ data }) {
      this.mtsdictionaryData = data;
    },
    setEnrollmentCount ({ enrollment_count }) {
      this.enrollmentCount = enrollment_count;
    },
    setTailoringDistribution (data) {
      this.tailoringDistribution = data;
    },
    setUpdatedCohortTask (data) {
      this.updatedCohortTask = data;
    },
    setStartedUpdateCohortTask (data) {
      if (!data.date_done) {
        data.date_done = new Date().toISOString();
      }

      if (this.updatedCohortTask) {
        this.updatedCohortTask = {...this.updatedCohortTask, started: data };
      } else {
        this.updatedCohortTask = { started: data };
      }
    },
    // Cohort Group Actions //
    async getCohortGroupList () {
      return this.loadObjectList({
        mutation: this.setCohortGroupList,
        urls: cohortGroupUrls,
        urlParams: {
          coachId: this.coachId,
        }
      });
    },
    async saveCohortGroup (instance) {
      return this.saveInstanceData({
        urls: cohortGroupUrls,
        urlParams: {
          coachId: this.coachId,
          cohortGroupId: instance.id
        },
        data: instance
      });
    },
    async deleteCohortGroup (instance) {
      return this.deleteInstanceData({
        urls: cohortGroupUrls,
        urlParams: {
          coachId: this.coachId,
          cohortGroupId: instance.id
        },
        data: instance,
      });
    },
    // Cohort Actions //
    async getCohortList () {
      return this.loadObjectList({
        mutation: this.setCohortList,
        urls: cohortUrls,
        urlParams: {
          coachId: this.coachId,
        }
      });
    },
    async loadCohortData () {
      return this.activeCohortId ? this.getCohortData({}).then(data => {
        this.setActiveCohort({ data: data });
      }) : this.getCohortList();
    },
    async getCohortData ({ cohortId = null }) {
      const url = cohortUrls.detail.stringify({
        coachId: this.coachId,
        cohortId: cohortId || this.activeCohortId,
      });
      return axios.get(url).then((response) => response.data);
    },
    async saveCohort (instance) {
      return this.saveInstanceData({
        urls: cohortUrls,
        urlParams: {
          coachId: this.coachId,
          cohortId: instance.id
        },
        data: instance
      });
    },
    async deleteCohort (instance) {
      return this.deleteInstanceData({
        urls: cohortUrls,
        urlParams: {
          coachId: this.coachId,
          cohortId: instance.id
        },
        data: instance,
      });
    },
    // Cohort Rules Actions //
    async getCohortRuleList (cohort) {
      return this.loadObjectList({
        mutation: this.setActiveCohortRuleList,
        urls: cohortRuleUrls,
        urlParams: {
          coachId: this.coachId,
          cohortId: cohort.id,
        }
      });
    },
    async saveCohortRule (instance) {
      return this.saveInstanceData({
        urls: cohortRuleUrls,
        urlParams: {
          coachId: this.coachId,
          cohortId: instance.cohortId,
          ruleId: instance.id
        },
        data: instance
      });
    },
    async deleteCohortRule (instance) {
      return this.deleteInstanceData({
        urls: cohortRuleUrls,
        urlParams: {
          coachId: this.coachId,
          cohortId: instance.cohortId,
          ruleId: instance.id
        },
        data: instance,
      });
    },
    // MTS Dictionary Data //
    async getMTSDictionaryData () {
      return this.loadObjectList({
        mutation: this.setMTSDictionaryData,
        urls: {
          list: mtsdictionaryDataUrl
        },
        urlParams: {
          coachId: this.coachId,
        }
      });
    },
    async getEnrollmentCount () {
      let url = coachEnrollmentDetailsUrl.stringify({ coachId: this.coachId });
      return axios.get(url).then((response) => {
        this.setEnrollmentCount(response.data);
      });
    },
   async getTailoringDistribution (cohort) {
      let url = cohortTailoringDistributionUrl.stringify({
        coachId: this.coachId,
        cohortId: cohort.id
      });
      return axios.get(url).then((response) => {
        this.setTailoringDistribution(response.data);
      });
    },
    async getCohortMembershipTask (cohort) {
      let url = updateCohortMembershipUrl.stringify({
        coachId: this.coachId,
        cohortId: cohort.id
      });
      return axios.get(url).then((response) => {
        this.setUpdatedCohortTask(response.data);
      });
    },
    async triggerCohortMembershipTask (cohort) {
      let url = updateCohortMembershipUrl.stringify({
        coachId: this.coachId,
        cohortId: cohort.id
      });
      return axios.post(url).then((response) => {
        this.setStartedUpdateCohortTask(response.data);
      });
    }
  },
});
