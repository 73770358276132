import axios from "axios";
import { defineStore } from 'pinia';

import { coachBaseUrl, tipsDocumentUrl } from "../urls.js";

const coachInfo = coachBaseUrl.match(location.pathname);
const initialState = {
  tipsData: {},
  tipsDocumentURL: coachInfo ? tipsDocumentUrl.stringify(coachInfo) : null,
  isOpen: false,
  currentTip: null,
};

export const useTipsStore = defineStore('tips', {
  state: () => initialState,
  actions: {
    updateTipsData (payload) {
      this.tipsData = payload;
    },
    toggleTipOpenState () {
      this.isOpen = this.isOpen === false;
    },
    displaySelectedTip (tip) {
      this.currentTip = tip;
    },
    async loadTipsData () {
      return axios.get(this.tipsDocumentURL).then((response) => {
          this.updateTipsData(response.data.content);
      });
    },
  },
});
