<template>
    <div v-if="playbook.is_released"
        class="playbook-item bar-graph-container graph-seafoam barGraphContainer">
        <p>
            <b>{{ playbook.exam_name }}</b> | Date:
            <template v-if="playbook.exam_date">{{ examDate }}</template>
            <template v-else>{{ playbook.custom_date_message }}</template>
        </p>
        <p>
            <i>
                {{ playbook.graph_message }}
            </i>
        </p>
        <div class="graph" role="presentation">
            <div class="bar bar-solid bar-right-aligned started"
                :data-percent="playbook.planning_duration_percentage">
            </div>
        </div>
        <div class="axis-container" role="presentation">
            <div class="x-axis" :data-ticks="playbook.remaining_date">
                <div v-for="n in tickCount" class="day-tick"></div>
            </div>
            <div class="axis-labels" :class="tickLabelClass">
                <template v-for="n in tickCount">
                    <div key="start" v-if="n == 1" class="day-number start">{{ tickCount - n }}</div>
                    <div :key="tickCount - n" v-else-if="n != tickCount" class="day-number">{{ tickCount - n }}</div>
                </template>
                <div class="x-label due">{{ playbook.assignment_type }}</div>
                <div class="day-number end"></div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "PlaybookActivityHistory",
  props: {
    playbook: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    examDate () {
        return moment(this.playbook.exam_date).format('M.D.YY - h:mmA');
    },
    tickLabelClass () {
        if(this.tickCount > 14) {
            return this.tickCount % 2 == 0 ? 'hide-even-tick' : 'hide-odd-tick';
        }

        return ''
    },
    tickCount () {
        return this.playbook.remaining_date + 1;
    }
  }
};
</script>
