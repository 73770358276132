import { defineStore } from 'pinia';

const initialState = {
  ExistedFlag: true,
  selectedFile: null,
  triggerUpload: false,
}

export const useIconPickerStore = defineStore('icon-picker', {
  namespaced: true,
  state: () => initialState,
  actions: {
    setExistedFlag (payload){
      this.ExistedFlag = payload;
    },
    updateSelectedFile (value) {
      this.selectedFile = value;
    },
    setTriggerUpload (payload) {
      this.triggerUpload = payload;
    },
    triggerUploadNew () {
      this.setTriggerUpload(true);
    },
  },
});
