<template>
    <focus-trap>
        <div class="tips-container">
            <div class="modal-header">
                <h2>Tips of the Week | <span>Expanded</span></h2>
                <button
                    class="btn modal-close"
                    type="button"
                    @click="toggleTipOpenState()"
                    aria-label="Close Tips of the Week Modal"
                >
                    <svg class="nav-icon icon-navy">
                        <use xlink:href="/static/img/IconSprite.svg#exit"></use>
                    </svg>
                </button>
            </div>
            <div class="header-block">
                <div class="tips-icon"></div>
                <div class="tag">Advice</div>
            </div>
            <div v-html="currentTip.html" class="tip content"></div>
        </div>
    </focus-trap>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTipsStore } from '../../stores/tips.js';

import { FocusTrap } from 'focus-trap-vue';
import * as loadTips from '../../../js/content-scripts.js';

export default {
  name: "CarouselOfTipsCard",
  components: {
      FocusTrap,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(useTipsStore, ["toggleTipOpenState"]),
  },
  computed: {
    ...mapState(useTipsStore, [
      'currentTip',
    ]),
  },
  mounted: function () {
    loadTips.loadContent();
  }
};
</script>
