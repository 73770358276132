<template>
    <div class="coach-container dash-section">
        <template v-if="coachList.length">
            <coach-activity-header v-if="coachId" @select-coach="updateCoach"/>
            <loading-indicator v-if="loading" />
            <div v-else class="card card-has-divider card-coach-activity activityBreakdown coachActivityBreakdown">
                <coach-activity-overview />
                <coach-activity-breakdown />
            </div>
        </template>
        <template v-else>
            <div class="card-header">
                <h2>Coach Activity Breakdown</h2>
            </div>
            <div class="card card-empty">
                <div class="icon-container">
                    <svg class="empty-icon" role="presentation">
                        <use xlink:href="/static/img/IconSprite.svg#activity"></use>
                    </svg>
                </div>
                <p>There are currently no active coaches.</p>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useStudentActivityStore } from '../../stores/activity-history.js';

import { loadingMixin } from '../../mixins/loading.js'
import LoadingIndicator from '../generic/LoadingIndicator.vue';

import ActivityBarGraph from './ActivityBarGraph.vue';
import CoachActivityHeader from './CoachActivityHeader.vue';
import CoachActivityOverview from './CoachActivityOverview.vue';
import CoachActivityBreakdown from './CoachActivityBreakdown.vue';

export default {
    name: "InstitutionCoachActivityHistory",
    mixins: [
        loadingMixin,
    ],
    components: {
        ActivityBarGraph,
        CoachActivityBreakdown,
        CoachActivityHeader,
        CoachActivityOverview,
        LoadingIndicator,
    },
    computed: {
        ...mapState(useStudentActivityStore, [
            'coachId',
            'coachList',
        ]),
    },
    methods: {
        ...mapActions(useStudentActivityStore, [
            'getActivityCoachUserAnalytics',
            'getActivityMessageAnalytics',
            'getActivitySurveyAnalytics',
            'getActivityExamPlaybookAnalytics',
            'getActivityToDoAnalytics',
            'getCoachList',
            'setCoachId',
        ]),
        loadInterfaceData() {
            this.startLoading();
            Promise.allSettled([
                this.getActivityCoachUserAnalytics(),
                this.getActivityMessageAnalytics(),
                this.getActivitySurveyAnalytics(),
                this.getActivityExamPlaybookAnalytics(),
                this.getActivityToDoAnalytics(),
            ]).then(() => {
                this.stopLoading();
            });
        },
        updateCoach(coachId) {
            this.setCoachId(coachId);
            this.loadInterfaceData();
        }
    },
    created() {
        this.startLoading();
        this.getCoachList().then(() => {
            if(!this.coachList.length) { return }
            this.setCoachId(this.coachList[0].id);
            this.loadInterfaceData();
        });
    },
}
</script>
