<template>
    <FormKit
      type="form"
      v-model="formData"
      :actions="false"
      :input-errors="formErrors"
    >
        <ul class="errorlist" v-if="formErrors.length">
            <li v-for="error in formErrors.base">{{ error }}</li>
        </ul>
        <FormKit
            name="name"
            label='Group Name'
            outer-class="survey_question"
            label-class="survey_prompt"
            placeholder="Group Name"
            type="text"
            validation="required:trim"
        >
          <template #label="context">
            <div :class="context.classes.label" :for="context.id">
              Group Name <span class="required-item">*</span>
            </div> 
          </template>
        </FormKit>
        <FormKit
            v-if="this.instanceData.id"
            name="cohort_group"
            label="Update collection"
            outer-class="survey_question"
            label-class="survey_prompt"
            placeholder="-----"
            type="select"
            :options="cohortGroupOptions"
        />
        <FormKit
            v-else
            name="cohort_group"
            label="Add Group to Collection"
            outer-class="survey_question"
            label-class="survey_prompt"
            placeholder="-----"
            type="select"
            :options="cohortGroupOptions"
        />
        <h3>Advanced Setup</h3>
        <div class="form-section">
            <FormKit
                name="source"
                label="MTS Source"
                outer-class="survey_question"
                label-class="survey_prompt"
                type="select"
                :options="sourceOptions"
                placeholder="-----"
            />
            <FormKit
                name="characteristic"
                label="Assign MTS Characteristic"
                help="Use the selected characteristic to tailor to students that meet the group criteria"
                outer-class="survey_question"
                label-class="survey_prompt"
                help-class="helper-text"
                type="select"
                :options="characteristicOptions"
                placeholder="-----"
            />
        </div>
        <div class="button-block">
            <button type="button" class="btn btn-white"
                @click="cancelForm">
                Cancel
            </button>
            <button type="button" class="btn"
                @click="saveInstance">
                Save
            </button>
            <button v-if="editing" type="button"
                class="btn btn-delete"
                @click="deleteInstance()">
                Delete
            </button>
        </div>
    </FormKit>
</template>

<script>
import { mapState } from "pinia";
import { useTailoringStore } from './stores/tailoring.js'

import { formDataMixin } from '../../mixins/formData.js';

export default {
  name: 'CohortForm',
  data() {
    return {}
  },
  mixins: [
    formDataMixin
  ],
  computed: {
    ...mapState(useTailoringStore, [
      'cohortGroups',
      'mtsdictionaryData',
      'sources',
      'characteristics',
      'sourcedCharacteristics'
    ]),
    selectedSource () {
      return this.formData.source;
    },
    activeSourceCharacteristics () {
      let source = this.selectedSource;
      if (source !== null) {
        for (let sourceIndex of this.sourcedCharacteristics) {
          if (sourceIndex.source === source) {
            return sourceIndex.characteristics;
          }
        }
      }
      return this.characteristics;;
    },
    cohortGroupOptions () {
      return [this.emptyOption].concat(this.cohortGroups.map(cohortGroup => {
        return {
          label: cohortGroup.name,
          value: cohortGroup.id,
        }
      }));
    },
    sourceOptions () {
      return [this.emptyOption].concat(this.sources.filter(source => source).map(source => {
        return {
          label: source,
          value: source,
        }
      }));
    },
    characteristicOptions () {
      let characteristics = this.activeSourceCharacteristics;
      return [this.emptyOption].concat(characteristics.map(characteristic => {
        return {
          label: characteristic.name,
          value: characteristic.name,
        }
      }));
    },
  },
}
</script>
