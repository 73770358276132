import { createApp } from 'vue';
import { createPinia } from 'pinia';
import axios from 'axios';
import timeago from 'vue-timeago3'
import { plugin, defaultConfig } from "@formkit/vue";
import { getElementClass, getWrapperClass } from "../vue/vue-formulate-styles";

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const pinia = createPinia();

const createECoachApp = (rootComponent, props) => {
  const { DateTime } = require("luxon");
  const ecoachApp = createApp(rootComponent, props);
  ecoachApp.config.globalProperties.$luxonDateTime = DateTime;
  ecoachApp.use(timeago, {
    name: 'TimeAgo',
    locale: 'en-US',
  });
  ecoachApp.use(pinia);
  ecoachApp.use(plugin, defaultConfig({
    config: {
      classes: {
        outer: "form-field",
        wrapper: (context, classes) => getWrapperClass(context, classes),
        label: "form-label",
        element: (context, classes) => getElementClass(context, classes),
        errors: "form-field-errors",
        error: "form-field-error",
      },
    }
  }));

  return ecoachApp;
}

import FAQModal from '../vue/components/FAQsupport/FAQModal.vue';
import FAQMenuItem from '../vue/components/FAQsupport/FAQMenuItem.vue';
import GradeLeftVisualization from '../vue/components/gradevisualization/GradeLeftVisualization.vue';
import ImageSelectModal from '../vue/components/imageselect/ImageSelectModal.vue';
import ImageSelectItem from '../vue/components/imageselect/ImageSelectItem.vue';
import CarouselOfTips from '../vue/components/tips/CarouselOfTips.vue';
import ToDoList from '../vue/components/ToDoList/ToDoList.vue';
import IconSelectItem from '../vue/components/iconpicker/IconSelectItem.vue';
import ActivityHistoryItem from '../vue/components/studentactivityhistory/ActivityHistoryItem.vue';
import ActivityHistoryCreditOverview from '../vue/components/studentactivityhistory/ActivityHistoryCreditOverview.vue';
import BulletinActivityHistory from '../vue/components/studentactivityhistory/BulletinActivityHistory.vue';
import ToDoActivityHistory from '../vue/components/studentactivityhistory/ToDoActivityHistory.vue';
import PlaybookActivityHistory from '../vue/components/studentactivityhistory/PlaybookActivityHistory.vue';
import InstitutionCoachActivityHistory from '../vue/components/institutionactivityhistory/InstitutionCoachActivityHistory.vue';
import CohortOverview from '../vue/admin/tailored-cohorts/CohortOverview.vue';
import CohortDetail from '../vue/admin/tailored-cohorts/CohortDetail.vue';
import AddCohortPage from '../vue/admin/tailored-cohorts/AddCohortPage.vue';
import BulletinList from '../vue/student/bulletin/BulletinList.vue';
import BulletinStarButton from '../vue/student/bulletin/BulletinStarButton.vue';



const vueImageSelectionModal = document.getElementById('ImageSelectionModal');
if(vueImageSelectionModal){
  var imageSelectList = createECoachApp(ImageSelectModal);
  imageSelectList.mount(vueImageSelectionModal);
}

const vueSelectImage = document.getElementById('VueSelectImage');
if(vueSelectImage){
  var imageSelect = createECoachApp(ImageSelectItem, { ...vueSelectImage.dataset });
  imageSelect.mount(vueSelectImage);
}

const vueSelectIcon = document.getElementById('VueSelectIcon');
if(vueSelectIcon){
  var iconSelect = createECoachApp(IconSelectItem)
  iconSelect.mount(vueSelectIcon);
}

const vueFaqMenu = document.getElementById('FAQMenuItem');
if (vueFaqMenu) {
  var faqMenuItem = createECoachApp(FAQMenuItem);
  faqMenuItem.mount(vueFaqMenu);
}

const vueFaqModal = document.getElementById('FAQModal');
if (vueFaqModal) {
  var faqModal = createECoachApp(FAQModal)
  faqModal.mount(vueFaqModal);
}

const vueGradeLeftVisualization = document.getElementById('GradeLeftVisualization')
if (vueGradeLeftVisualization) {
  var gradeVisualization = createECoachApp(GradeLeftVisualization, {...vueGradeLeftVisualization.dataset});
  gradeVisualization.mount('#GradeLeftVisualization');
}

const vueCarouselOfTips = document.getElementById('CarouselOfTips');
if (vueCarouselOfTips) {
  var carouselOfTips = createECoachApp(CarouselOfTips);
  carouselOfTips.mount(vueCarouselOfTips);
}

const vueToDoList = document.getElementById('ToDoList');
if(vueToDoList){
  var todoList = createECoachApp(ToDoList);
  todoList.mount(vueToDoList);
}

const vueActivityHistoryItem = document.getElementById('ActivityHistoryItem');
if (vueActivityHistoryItem) {
  var activityHistoryItem = createECoachApp(ActivityHistoryItem)
  activityHistoryItem.mount(vueActivityHistoryItem);
}
const vueActivityHistoryCreditOverview = document.getElementById('ActivityHistoryCreditOverview');
if (vueActivityHistoryCreditOverview) {
  var activityHistoryCreditOverview = createECoachApp(ActivityHistoryCreditOverview)
  activityHistoryCreditOverview.mount(vueActivityHistoryCreditOverview);
}
const vueBulletinActivityHistory = document.getElementById('BulletinActivityHistory');
if (vueBulletinActivityHistory) {
  var bulletinActivityHistory = createECoachApp(BulletinActivityHistory)
  bulletinActivityHistory.mount(vueBulletinActivityHistory);
}
const vueToDoActivityHistory = document.getElementById('ToDoActivityHistory');
if (vueToDoActivityHistory) {
  var toDoActivityHistory = createECoachApp(ToDoActivityHistory)
  toDoActivityHistory.mount(vueToDoActivityHistory);
}
const vuePlaybookActivityHistory = document.getElementById('PlaybookActivityHistory');
if (vuePlaybookActivityHistory) {
  var playbookActivityHistory = createECoachApp(PlaybookActivityHistory)
  playbookActivityHistory.mount(vuePlaybookActivityHistory);
}

const vueInstitutionCoachActivityHistory = document.getElementById('InstitutionCoachActivityHistory');
if (vueInstitutionCoachActivityHistory) {
  var institutionCoachActivityHistory = createECoachApp(InstitutionCoachActivityHistory, {...vueInstitutionCoachActivityHistory.dataset})
  institutionCoachActivityHistory.mount(vueInstitutionCoachActivityHistory);
}

const vueStarButtons = document.getElementsByClassName('VueStarButtonWrapper');
for (const vueStarButton of vueStarButtons) {
  var vueBulletinStarButton = createECoachApp(BulletinStarButton, {...vueStarButton.dataset});
  vueBulletinStarButton.mount(vueStarButton);
}

const baseComponents = {
  "cohort-detail": CohortDetail,
  "cohort-overview": CohortOverview,
  "add-cohort-page": AddCohortPage,
  "bulletin-list": BulletinList,
}

const vueWrapper = document.getElementById('VueWrapper');
if (vueWrapper) {
  const initialComponent = baseComponents[vueWrapper.dataset.component];
  const app = initialComponent ? createECoachApp(initialComponent, {...vueWrapper.dataset}) : null;

  if (app) {
    app.mount(vueWrapper);
  }
}
