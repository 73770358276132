import axios from "axios";
import { defineStore } from 'pinia';

import {
  coachBaseUrl,
  gradeDataUrl
} from '../urls.js';

const coachInfo = coachBaseUrl.match(location.pathname);

const initialState = {
  gradeData: {},
  gradeDataURL: coachInfo ? gradeDataUrl.stringify(coachInfo) : null,
}

export const useGradeVizStore = defineStore('grade-viz',{
  state: () => initialState,
  getters: {
    currentScore: state => state.gradeData.current_score,
    gradeEarned: state => state.gradeData.grade_earned,
    gradeLost: state => state.gradeData.grade_lost,
    calculatorActive: state => state.gradeData.calculator_active,
  },
  actions: {
    updateGradeData (payload) {
      this.gradeData = payload;
    },
    async loadGradeData () {
      return axios.get(this.gradeDataURL).then((response) => {
          this.updateGradeData(response.data);
      });
    },
  },
});
